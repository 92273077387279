import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function thirdPartyApi() {

    const callApi = useApiCall();

    const quickBookAuth = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.QUICK_BOOK.AUTH, data, onSuccess, onError);
    }
    const quickBookCallback = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.QUICK_BOOK.CALLBACK, data, onSuccess, onError);
    }
    const quickBookImportData = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.QUICK_BOOK.IMPORT_DATA, data, onSuccess, onError);
    }
    const fetchQuickBookAccountCharts = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.QUICK_BOOK.FETCH_ACCOUNT_CHARTS, data, onSuccess, onError);
    }
    const postQuickBookAccountCharts = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.QUICK_BOOK.POST_ACCOUNT_CHARTS, data, onSuccess, onError);
    }


    return {
        quickBookAuth,
        quickBookCallback,
        quickBookImportData,
        fetchQuickBookAccountCharts,
        postQuickBookAccountCharts
    }
}