
import * as yup from "yup";

export const LoginFormValidate = yup.object().shape({
    email: yup.string().email('Invalid Email').required('Email  required'),
    password: yup.string().required('Password required')
})

export const CAFormValidate = yup.object().shape({
    revrex_account_id: yup.number().nullable(),
    account: yup.object().shape({
        label: yup.string().required('Account Number Required'),
        value: yup.string().required('Account Number Required'),
    }).typeError('Account number required').required('Account number required'),
    financial_statement: yup.object().shape({
        label: yup.string().required('Report Required'),
        value: yup.string().required('Report Required'),
    }).required('Report required'),
    parenthisys_control: yup.object().shape({
        label: yup.string().required('Account type Required'),
        value: yup.string().required('Account type Required'),
    }).required('Account type required'),
    name: yup.string().required('Name required'),
    description: yup.string()
})

export const GLSearchFormValidate = yup.object().shape({
    month: yup.object().shape({
        label: yup.string().required('Month Required'),
        value: yup.string().required('Month Required'),
    }).required('Month required'),
    year: yup.object().shape({
        label: yup.string().required('Year Required'),
        value: yup.string().required('Year Required'),
    }).required('Year required'),

})

export const SalesExpenseValidate = yup.object().shape({
    sales: yup.array().of(
        yup.object().shape({
            checked: yup.boolean(),
            //id: yup.any(),
            parent_id: yup.number(),
            description: yup.string().nullable(),
            account_name: yup.string().nullable(),
            transaction_at: yup.string(),
            gross_sale: yup.number(),
            state_tax: yup.number(),
            // adjusted_bank_deposit: yup.number(),
            overshort: yup.number(),
            split: yup.array(),
            account_type: yup.string(),

            gross_sale_master_account_id: yup.object().when(['gross_sale', 'checked', 'split', 'account_type'], {
                // is: (grossSale: number, isChecked: boolean) => +grossSale && isChecked,
                is: (grossSale: number, isChecked: boolean, split: any[], account_type: string) => {
                    return +grossSale && isChecked && split.length === 0 && account_type === 'Gross_Sale';
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            state_tax_master_account_id: yup.object().when(['state_tax', 'checked', 'split', 'account_type'], {
                is: (stateTax: number, isChecked: boolean, split: any[], account_type: string) => {
                    return +stateTax && isChecked && split.length === 0 && account_type === 'State_Tax';
                },
                //is: (stateTax: number, isChecked: boolean, account_type: string) => +stateTax && isChecked && account_type == 'State_Tax',
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            /*adjusted_bank_deposit_account_id: yup.object().when(['adjusted_bank_deposit', 'checked'], {
                is: (adjustedBankDeposit: number, isChecked: boolean) => +adjustedBankDeposit && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),*/
            overshort_master_account_id: yup.object().when(['overshort', 'checked', 'account_type'], {
                //is: (overshort: number, isChecked: boolean, account_type: string) => +overshort && isChecked && account_type == 'Overshort',
                is: (overshort: number, isChecked: boolean, account_type: string) => {
                    return +overshort && isChecked && account_type === 'Overshort';
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
        })
    ),
})
export const BankExpenseValidate = yup.object().shape({
    banks: yup.array().of(
        yup.object().shape({
            checked: yup.boolean(),
            id: yup.number(),
            parent_id: yup.number(),
            description: yup.string().nullable(),
            account_name: yup.string().nullable(),
            transaction_at: yup.string(),
            deposit: yup.number(),
            split: yup.array(),
            // equity: yup.number(),
            // bank_transfer: yup.number(),
            // others: yup.number(),
            // adjusted_bank_deposit: yup.number(),
            deposit_debit_account_id: yup.object().when(['deposit', 'checked', 'split'], {
                is: (deposit: number, isChecked: boolean, split: any[]) => {
                    return +deposit && isChecked && split.length === 0;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            deposit_credit_account_id: yup.object().when(['deposit', 'checked', 'split'], {
                is: (deposit: number, isChecked: boolean, split: any[]) => {
                    return +deposit && isChecked && split.length === 0;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            /*equity_master_account_id: yup.object().when(['equity', 'checked'], {
                is: (equity: number, isChecked: boolean) => +equity && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            bank_transfer_master_account_id: yup.object().when(['bank_transfer', 'checked'], {
                is: (bankTransfer: number, isChecked: boolean) => +bankTransfer && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            others_master_account_id: yup.object().when(['others', 'checked'], {
                is: (others: number, isChecked: boolean) => +others && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            adjusted_bank_deposity_master_account_id: yup.object().when(['adjusted_bank_deposit', 'checked'], {
                is: (adjustedBankDeposit: number, isChecked: boolean) => +adjustedBankDeposit && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),*/
        })
    ),
})
export const BankRevenueValidate = yup.object().shape({
    revenues: yup.array().of(
        yup.object().shape({
            checked: yup.boolean(),
            id: yup.number(),
            parent_id: yup.number(),
            description: yup.string().nullable(),
            account_name: yup.string().nullable(),
            transaction_at: yup.string(),
            deposit: yup.number(),
            equity: yup.number(),
            bank_transfer: yup.number(),
            others: yup.number(),
            adjusted_bank_deposit: yup.number(),
            equity_split : yup.array(),
            bank_transfer_split : yup.array(),
            others_split : yup.array(),
            deposit_master_account_id: yup.object().when(['deposit', 'checked'], {
                is: (deposit: number, isChecked: boolean) => {
                    return +deposit && isChecked
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            equity_master_account_id: yup.object().when(['equity', 'checked','equity_split'], {
                //is: (equity: number, isChecked: boolean) => +equity && isChecked,
                is: (equity: number, isChecked: boolean, equity_split: any[]) => {
                    return +equity && isChecked && equity_split.length === 0;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            bank_transfer_master_account_id: yup.object().when(['bank_transfer', 'checked', 'bank_transfer_split'], {
                //is: (bankTransfer: number, isChecked: boolean) => +bankTransfer && isChecked,
                is: (bankTransfer: number, isChecked: boolean, bank_transfer_split: any[]) => {
                    return +bankTransfer && isChecked && bank_transfer_split.length === 0;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            others_master_account_id: yup.object().when(['others', 'checked', 'others_split'], {
                // is: (others: number, isChecked: boolean) => +others && isChecked,
                is: (others: number, isChecked: boolean, others_split: any[]) => {
                    return +others && isChecked && others_split.length === 0;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            /*adjusted_bank_deposity_master_account_id: yup.object().when(['adjusted_bank_deposit', 'checked'], {
                is: (adjustedBankDeposit: number, isChecked: boolean) => +adjustedBankDeposit && isChecked,
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),*/
        })
    ),
})
export const BankReconcileValidate = yup.object().shape({
    reconciles: yup.array().of(
        yup.object().shape({
            checked: yup.boolean(),
            id: yup.number()
        })
    ),
})
export const SettingsBudgetValidate = yup.object().shape({
    budget_based_on: yup.object().shape({
        label: yup.string().required('Field is required'),
        value: yup.string().required('Field is required'),
    }).typeError('Field is required').required('Field is required'),
    first_month_of_fiscal_year: yup.object().shape({
        label: yup.string().required('Field is required'),
        value: yup.string().required('Field is required'),
    }).typeError('Field is required').required('Field is required'),
    first_month_of_income_tax_year: yup.object().shape({
        label: yup.string().required('Field is required'),
        value: yup.string().required('Field is required'),
    }).typeError('Field is required').required('Field is required')
})

export const SalesValidate = yup.object().shape({
    gl_account: yup.object().shape({
        label: yup.string().required('Field is required'),
        value: yup.string().required('Field is required'),
    }).typeError('Field is required').required('Field is required'),

})

export const BudgetValidate = yup.object().shape({
    budgets: yup.array().of(
        yup.object().shape({
            master_chart_of_account_number: yup.string(),
            master_chart_of_account_name: yup.string(),
            master_chart_of_account_id: yup.number(),
            parent_id: yup.number(),
            month_0: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_1: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_2: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_3: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_4: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_5: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_6: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_7: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_8: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_9: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_10: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
            month_11: yup.object().shape({
                amount: yup.string().typeError('Invalid amount'),
                is_editable: yup.boolean()
            }),
        })
    ),
})

export const RevRexBankAccSyncGlValidate = yup.object().shape({
    banks: yup.array().of(
        yup.object().shape({
            bank_account_id: yup.object().shape({
                label: yup.string().required('Bank account is required'),
                value: yup.string().required('Bank account is required'),
            }).typeError('Bank account is required').required('Bank account is required'),
            gl_account: yup.object().shape({
                label: yup.string().required('GL is required'),
                value: yup.string().required('GL is required'),
            }).typeError('GL is required').required('GL is required'),
        })
    ).test('unique_gl', 'Same VP can not be assigned to multiple bank accounts', (values: any) => {
        const gl_acc: string[] = values
            .filter((value: any) => value['gl_account'] && value['gl_account']['value'])
            .map((value: any) => value['gl_account']['value']);
        if (values.length == gl_acc.length) {
            let findDuplicates = gl_acc.filter((item: string, index: number) => gl_acc.indexOf(item) !== index)
            return findDuplicates.length ? false : true;
        } else {
            return true;
        }
    })
})

export const RevRexSaleAccSyncGlValidate = yup.object().shape({
    sales: yup.array().of(
        yup.object().shape({
            sale_account_id: yup.object().shape({
                label: yup.string().required('Sale account is required'),
                value: yup.string().required('Sale account is required'),
            }).typeError('Sale account is required').required('Sale account is required'),
            gl_account: yup.object().shape({
                label: yup.string().required('GL is required'),
                value: yup.string().required('GL is required'),
            }).typeError('GL is required').required('GL is required'),
        })
    ).test('unique_gl', 'Same VP can not be assigned to multiple sale accounts', (values: any) => {
        const gl_acc: string[] = values
            .filter((value: any) => value['gl_account'] && value['gl_account']['value'])
            .map((value: any) => value['gl_account']['value']);

        if (values.length == gl_acc.length) {
            let findDuplicates = gl_acc.filter((item: string, index: number) => gl_acc.indexOf(item) !== index)
            return findDuplicates.length ? false : true;
        } else {
            return true;
        }
    })
})

export const BankReconcileFormValidate = yup.object().shape({
    beginningBalance: yup.string()
        .matches(/^-?\d*(\.\d{1,2})?$/, 'Must be a number with up to two decimal places')
        .required('Beginning is required'),
    endingBalance: yup.string()
        .matches(/^-?\d*(\.\d{1,2})?$/, 'Must be a number with up to two decimal places')
        .required('Ending is required'),
})

export const CreateTransactionAdjustmentValidate = yup.object().shape({
    transaction_adjustment: yup.array().of(
        yup.object().shape({
            description: yup.string()
                .nullable()
                .strict()
                .typeError('Description must be a string'),
            transaction_at: yup.string().required('Date is required').typeError('Enter a  valid date'),
            adjustment_debit: yup.number().oneOf([yup.ref('adjustment_credit')], 'Amounts must match').required('Debit amount is required').typeError('Enter a valid number'),
            adjustment_credit: yup.number().oneOf([yup.ref('adjustment_debit')], 'Amounts must match').required('Credit amount is required').typeError('Enter a valid number'),
            adjustment_master_credit_account_id: yup.object().shape({
                label: yup.string().required('GL is required'),
                value: yup.string().required('GL is required'),
            }).required('GL is required'),
            adjustment_master_debit_account_id: yup.object().shape({
                label: yup.string().required('GL is required'),
                value: yup.string().required('GL is required'),
            }).required('GL is required'),
        
        })/*.test('amounts-match', function (value) {
            const { adjustment_debit, adjustment_credit } = value;
        
            if (adjustment_debit !== adjustment_credit) {
                // Set the error message for both fields specifically
                return this.createError({
                    path: 'adjustment_debit',
                    message: 'Amounts must match',
                });
            }
        
            return true;
        })*/
    )
})

export const PersonalBalanceValidate = yup.object().shape({
    personal_balance_sheet_info_self: yup.object().shape({
        salutation: yup.string(),
        first_name: yup.string(),
        last_name: yup.string(),
        initial: yup.string(),
        current_address: yup.string(),
        current_city: yup.string(),
        current_state: yup.string(),
        current_zip: yup.string(),
        current_country: yup.string(),
        current_social_security_number: yup.string(),
        current_date_of_birth: yup.string(),
        current_residence: yup.string(),
        current_owner_type: yup.string(),
        current_years: yup.number(),
        current_months: yup.number(),
        current_date: yup.string(),
        previous_address: yup.string(),
        previous_city: yup.string(),
        previous_state: yup.string(),
        previous_zip: yup.string(),
        previous_date: yup.string(),
        previous_owner_type: yup.string(),
        previous_years: yup.number(),
        previous_months: yup.number(),
        previous_personal_phone: yup.string(),
        previous_work_phone: yup.string(),
        employer_name: yup.string(),
        employer_address: yup.string(),
        employer_city: yup.string(),
        employer_state: yup.string(),
        employer_zip: yup.string(),
        employer_position: yup.string(),
        employer_title: yup.string(),
        employer_service_yrs: yup.number(),
        employer_service_months: yup.number(),
        previous_employer_name: yup.string(),
        previous_employer_service_yrs: yup.number(),
        previous_employer_service_months: yup.number(),
        previous_employer_wk_phone: yup.string(),
        previous_employer_position: yup.string(),
        previous_employer_title: yup.string(),
        dependants: yup.array().of(
            yup.object().shape({
                name: yup.string(),
                relationship: yup.string()
            })
        )
    }),
    personal_balance_sheet_info_spouse: yup.object().shape({
        salutation: yup.string(),
        first_name: yup.string(),
        last_name: yup.string(),
        initial: yup.string(),
        current_address: yup.string(),
        current_city: yup.string(),
        current_state: yup.string(),
        current_zip: yup.string(),
        current_country: yup.string(),
        current_social_security_number: yup.string(),
        current_date_of_birth: yup.string(),
        current_residence: yup.string(),
        current_owner_type: yup.string(),
        current_years: yup.number(),
        current_months: yup.number(),
        current_date: yup.string(),
        previous_address: yup.string(),
        previous_city: yup.string(),
        previous_state: yup.string(),
        previous_zip: yup.string(),
        previous_date: yup.string(),
        previous_owner_type: yup.string(),
        previous_years: yup.number(),
        previous_months: yup.number(),
        previous_personal_phone: yup.string(),
        previous_work_phone: yup.string(),
        employer_name: yup.string(),
        employer_address: yup.string(),
        employer_city: yup.string(),
        employer_state: yup.string(),
        employer_zip: yup.string(),
        employer_position: yup.string(),
        employer_title: yup.string(),
        employer_service_yrs: yup.number(),
        employer_service_months: yup.number(),
        previous_employer_name: yup.string(),
        previous_employer_service_yrs: yup.number(),
        previous_employer_service_months: yup.number(),
        previous_employer_wk_phone: yup.string(),
        previous_employer_position: yup.string(),
        previous_employer_title: yup.string(),
        dependants: yup.array().of(
            yup.object().shape({
                name: yup.string(),
                relationship: yup.string()
            })
        )
    }),
    balance_sheet_assets: yup.array().of(
        yup.object().shape({
            description: yup.string(),
            amount: yup.number()
        })
    ),
    balance_sheet_liabilities: yup.array().of(
        yup.object().shape({
            description: yup.string(),
            amount: yup.number()
        })
    ),
    personal_balance_sheet_annual_income: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            self_amount: yup.number(),
            spouse_amount: yup.number()
        })
    ),
    personal_balance_sheet_section_one_cash_type: yup.array().of(
        yup.object().shape({
            institution_name: yup.string(),
            title: yup.string(),
            balance_amount: yup.number()
        })
    ),
    personal_balance_sheet_section_one_retirement_type: yup.array().of(
        yup.object().shape({
            institution_name: yup.string(),
            title: yup.string(),
            balance_amount: yup.number()
        })
    ),
    personal_balance_sheet_section_one_certificate_type: yup.array().of(
        yup.object().shape({
            institution_name: yup.string(),
            title: yup.string(),
            balance_amount: yup.number()
        })
    ),
    personal_balance_sheet_section_two_equities: yup.array().of(
        yup.object().shape({
            owner: yup.string(),
            holding_party: yup.string(),
            name_of_portfolio: yup.string(),
            amount: yup.number(),
        })
    ),
    personal_balance_sheet_section_two_equities_loan: yup.array().of(
        yup.object().shape({
            loan_against_portfolio: yup.string(),
            borrower_name: yup.string(),
            guarantor_name: yup.string(),
            loan_amount: yup.number(),
        })
    ),
    personal_balance_sheet_section_two_bonds: yup.array().of(
        yup.object().shape({
            owner: yup.string(),
            holding_party: yup.string(),
            name_of_portfolio: yup.string(),
            amount: yup.number(),
        })
    ),
    personal_balance_sheet_section_two_bonds_loan: yup.array().of(
        yup.object().shape({
            loan_against_portfolio: yup.string(),
            borrower_name: yup.string(),
            guarantor_name: yup.string(),
            loan_amount: yup.number(),
        })
    ),
    personal_balance_sheet_section_two_securities: yup.array().of(
        yup.object().shape({
            owner: yup.string(),
            holding_party: yup.string(),
            name_of_portfolio: yup.string(),
            amount: yup.number(),
        })
    ),
    personal_balance_sheet_section_two_securities_loan: yup.array().of(
        yup.object().shape({
            loan_against_portfolio: yup.string(),
            borrower_name: yup.string(),
            guarantor_name: yup.string(),
            loan_amount: yup.number(),
        })
    ),
    personal_balance_sheet_section_three_life_insurance: yup.array().of(
        yup.object().shape({
            insurance_company_name: yup.string(),
            insured_person_name: yup.string(),
            beneficiary_name: yup.string(),
            term_date: yup.string(),
            term_amount: yup.number(),
            whole_life_amount: yup.number(),
            loan_amount: yup.number(),
        })
    ),
    personal_balance_sheet_section_four_receivable_due: yup.array().of(
        yup.object().shape({
            debtor_name: yup.string(),
            description: yup.string(),
            due_date: yup.string(),
            repayment_terms: yup.string(),
            balance_amount: yup.number(),
        })
    ),
    personal_balance_sheet_section_five_real_estate_primary_property: yup.array().of(
        yup.object().shape({
            address: yup.string(),
            build_date: yup.number(),
            term: yup.string(),
            due_date: yup.string(),
            monthly_payment_amount: yup.number(),
            purchase_amount: yup.number(),
            market_value_amount: yup.number(),
            mortgage_balance_amount: yup.number(),
        })
    ),
    personal_balance_sheet_section_five_real_estate_other_property: yup.array().of(
        yup.object().shape({
            address: yup.string(),
            build_date: yup.number(),
            term: yup.string(),
            due_date: yup.string(),
            monthly_payment_amount: yup.number(),
            purchase_amount: yup.number(),
            market_value_amount: yup.number(),
            mortgage_balance_amount: yup.number(),
        })
    ),
    section_six_profit_sharing: yup.array().of(
        yup.object().shape({
            name: yup.string(),
            account_type: yup.string(),
            value: yup.number(),
            vested: yup.number(),
            loans: yup.number(),
        })
    ),
    section_seven_automobiles: yup.array().of(
        yup.object().shape({
            year: yup.number(),
            make: yup.string(),
            model: yup.string(),
            miles: yup.string(),
            loan_balance: yup.number(),
            apprisal: yup.number(),
            value: yup.number(),
        })
    ),
    section_eight_personal_property: yup.array().of(
        yup.object().shape({
            description: yup.string(),
            insured: yup.string(),
            agent: yup.string(),
            phone: yup.string(),
            apprisal: yup.number(),
            loans: yup.number(),
            value: yup.number(),
        })
    ),
    section_nine_notes: yup.array().of(
        yup.object().shape({
            name: yup.string(),
            date_borrowed: yup.string(),
            amount_borrowed: yup.number(),
            due_date: yup.string(),
            payment: yup.number(),
            balance: yup.number(),
        })
    ),
    section_nine_other_notes: yup.array().of(
        yup.object().shape({
            name: yup.string(),
            date_borrowed: yup.string(),
            amount_borrowed: yup.number(),
            due_date: yup.string(),
            payment: yup.number(),
            balance: yup.number(),
        })
    ),
    section_ten: yup.array().of(
        yup.object().shape({
            name: yup.string(),
            high_balance: yup.number(),
            start_date: yup.string(),
            end_date: yup.string(),
            payment: yup.number(),
        })
    ),
});

export const PersonalIncomeValidate = yup.object().shape({
    section_one_primary_income: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            monthly_amount: yup.number(),
            annual_amount: yup.number(),
            notes: yup.string()
        })
    ),
    section_one_other_income: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            monthly_amount: yup.number(),
            annual_amount: yup.number(),
            notes: yup.string()
        })
    ),
    section_two_fixed_expense: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            monthly_amount: yup.number(),
            annual_amount: yup.number(),
            notes: yup.string()
        })
    ),
    section_two_variable_expense: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            monthly_amount: yup.number(),
            annual_amount: yup.number(),
            notes: yup.string()
        })
    ),
    section_three_investments: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            monthly_amount: yup.number(),
            annual_amount: yup.number(),
            notes: yup.string()
        })
    ),
    section_four_budgets: yup.array().of(
        yup.object().shape({
            title: yup.string(),
            monthly_amount: yup.number(),
            annual_amount: yup.number(),
            notes: yup.string()
        })
    ),
});

export const OpenningBalanceValidate = yup.object().shape({
    assets: yup.array().of(
        yup.object().shape({
            opening_balance: yup.string(), 
            account: yup.number(),
            name: yup.string().required('Name is required'),
            description: yup.string(),
            report_code: yup.string(), 
            financial_statement: yup.string(),
            master_chart_of_account_id: yup.number(),
            credit: yup.string(),
            debit: yup.string(),
        })
    ),
    liabilities: yup.array().of(
        yup.object().shape({
            opening_balance: yup.string(), 
            account: yup.number(),
            name: yup.string().required('Name is required'),
            description: yup.string(),
            report_code: yup.string(), 
            financial_statement: yup.string(),
            master_chart_of_account_id: yup.number(),
            credit: yup.string(),
            debit: yup.string(),
        })
    ),
});


export const CreditCardClassificationValidate = yup.object().shape({
    classifications: yup.array().of(
        yup.object().shape({
            checked: yup.boolean(),
            id: yup.number(),
            description: yup.string().nullable(),
            transaction_at: yup.string(),
            adjustment_debit: yup.number(),
            adjustment_credit: yup.number(),
            adjustment_master_debit_account_id: yup.object().when(['adjustment_debit', 'checked'], {
                is: (deposit: number, isChecked: boolean) => {
                    return +deposit && isChecked;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            adjustment_master_credit_account_id: yup.object().when(['deposit', 'checked'], {
                is: (deposit: number, isChecked: boolean) => {
                    return +deposit && isChecked;
                },
                then: (schema: any) => schema.shape({
                    label: yup.string().required('GL is required'),
                    value: yup.string().required('GL is required'),
                }).typeError('GL is required').required('GL is required'),
                otherwise: (schema: any) => schema.nullable()
            }),
            duplicate: yup.boolean(),
            posted: yup.boolean()
        })
    ),
})

export const QuickBookCOAValidate = yup.object().shape({
    coa: yup.array().of(
        yup.object().shape({
            checked: yup.boolean(),
            id: yup.number(),
            // parent_id: yup.number(),
            name: yup.string().nullable(),
            accountType: yup.string().nullable(),
            classification: yup.string(),
            account_id: yup.object().shape({
                label: yup.string().required('COA is required'),
                value: yup.string().required('COA is required'),
            }).required('COA is required'),
        })
    ),
})