import React, { useEffect, useState, ReactNode } from 'react'
import { Alert } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getQboSession } from '../../store/settings/selectors'
import { URLS } from '../../resources/constants'

interface Props { }

const ThirdPartyIntegrationList: React.FC<Props> = () => {

    const navigate = useNavigate();
    const quickBookSession = useSelector(getQboSession);

    useEffect(() => {
        if ( !quickBookSession ) {
            navigate(URLS.QUICKBOOK_DASHBOARD)
        }
    }, [quickBookSession])

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-12 align-items-center d-flex justify-content-between">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">
                                Integration
                            </h1>
                            <Link to={URLS.THIRD_PARTY_CONNECTION} className="d-flex gap-2 align-items-center btn btn-primary">
                                <img src="third-party-coonect.svg" alt="" />
                                <span>Create Connection</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                    <Alert variant={'info'}>
                            <i className="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;
                            <span>You are not connected with any available accounting software.</span>
                        </Alert>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThirdPartyIntegrationList
