import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getBranch } from '../../store/user/selectors'

import { thirdPartyApi } from '../../resources/hooks/api/thirdPartyApiHook'
import { URLS } from 'resources/constants'
import toast from 'react-hot-toast'
import { getQboSession } from '../../store/settings/selectors'
import { useSettingsApi } from '../../resources/hooks/api/useSettingsApiHook'

interface Props { }

const ThirdPartyConnection: React.FC<Props> = () => {

    const navigate = useNavigate();
    const { quickBookAuth } = thirdPartyApi();
    const branchData = useSelector(getBranch);
    const quickBookSession = useSelector(getQboSession);
    const { fetchAccountSettingsData } = useSettingsApi();

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<string>('quick-book')
    const newWindow: any = useRef(null);

    const handleLogin = () => {
        if (selectedItem === 'quick-book') {
            _handleQuickBookAuth();
        } else {
            toast.error('Sage Pay is not available yet')
        }
    }

    const _handleQuickBookAuth = () => {
        setIsLoading(true)
        quickBookAuth({branch_id: +branchData['id']}, (message: string, resp: any) => {
            const authUrl = resp.data.data.auth_url;
            _handleLoginWindow(authUrl);
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false)
            console.log(resp)
        })
    }

    const _handleLoginWindow = (authUrl: string) => {
        const width = 600;
        const height = 400;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);
        newWindow.current = window.open(authUrl, 'QuickBooks Login', `width=${width},height=${height},top=${top},left=${left}`);

        if ( newWindow.current ) {
            const checkIfWindowClosed = setInterval(() => {
                if ( newWindow.current && newWindow.current.closed ) {
                    clearInterval(checkIfWindowClosed);
                    handleWindowClose();
                }
            }, 500);

            newWindow.current.checkInterval = checkIfWindowClosed;
        }
    }

    const handleWindowClose = () => {
        getSettingsData();
        newWindow.current = null;
    }

    const getSettingsData = () => {
        setIsLoading(true)
        fetchAccountSettingsData({ branch_id: +branchData['id'] }, (msg: string, resp: any) => {
            setIsLoading(false)
        }, (msg: string, resp: any) => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        return () => {
            if ( newWindow.current ) {
                clearInterval(newWindow.current.checkInterval);
                newWindow.current.close();
            }

            if ( newWindow.current && !newWindow.current.closed ) {
                newWindow.current.close();
            }
        }
    }, [])

    useEffect(() => {
        if ( !quickBookSession ) {
            navigate(URLS.QUICKBOOK_DASHBOARD)
        }
    }, [quickBookSession])

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-12 align-items-center d-flex justify-content-between">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">
                                Create Connection
                            </h1>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                            <div className="loader"></div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12">
                                <div className='d-flex flex-column gap-4'>
                                    <span>Choose Your Preferred Accounting Solution: Sage or Quick Books Online</span>
                                    <div className='d-flex gap-4'>
                                        <div className='border' style={{ borderRadius: '5px' }}>
                                            <div className='align-items-center d-flex justify-content-center' style={{ backgroundColor: '#D9D9D9', borderTopRightRadius: '5px', borderTopLeftRadius: '5px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px' }}>
                                                <img src="sage.svg" alt="" className='h-60' />
                                            </div>
                                            <div className='align-items-center d-flex gap-1 justify-content-center pb-1 pt-1'>
                                                <input type="radio" id="sage" name="third-party" value="sage" onChange={(e: any) => setSelectedItem(e?.target?.value)} checked={selectedItem == 'sage'} />
                                                <label htmlFor="sage">Sage Pay</label>
                                            </div>
                                        </div>
                                        <div className='border' style={{ borderRadius: '5px' }}>
                                            <div className='align-items-center d-flex justify-content-center' style={{ backgroundColor: '#D9D9D9', borderTopRightRadius: '5px', borderTopLeftRadius: '5px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px' }}>
                                                <img src="quick-book.svg" alt="" className='h-60' />
                                            </div>
                                            <div className='align-items-center d-flex gap-1 justify-content-center pb-1 pt-1'>
                                                <input type="radio" id="quick-book" name="third-party" value="quick-book" checked={selectedItem == 'quick-book'} onChange={(e: any) => setSelectedItem(e?.target?.value)} />
                                                <label htmlFor="quick-book">Quick Books</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-md-5">
                            <div className="col-md-12 d-flex justify-content-end">
                                <Link to={"javascript:void(0)"} className="btn btn-primary" onClick={handleLogin}>
                                    {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : (
                                    <>
                                        <img src="third-party-coonect.svg" alt="" />
                                        <span>Create Connection</span>
                                    </>)}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThirdPartyConnection
