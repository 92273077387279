import { RootState } from '../types'

export const getSettings = (state: RootState) => state.settings
export const getAccountSettings = (state: RootState) => state.settings.accouting
export const getPrimarySalesAccountSettings = (state: RootState) => state.settings.primary_sales_account
export const getPrimaryStateTaxAccountSettings = (state: RootState) => state.settings.primary_state_tax_account
export const getBankAccountMatchSettings = (state: RootState) => state.settings.bank_account_match
export const getOpeningBalanceExistsSettings = (state: RootState) => state.settings.opening_balance_exists
export const getBudgetSettings = (state: RootState) => state.settings.budget_settings
export const getSystemAccessSettings = (state: RootState) => state.settings.system_access
export const getFirstLoginStatus = (state: RootState) => state.settings.fisrtLoginCheck

export const getQboSession = (state: RootState) => state.settings.qbo_session_expired
export const getQboLastLogin = (state: RootState) => state.settings.qbo_last_login
export const getSagePaySession = (state: RootState) => state.settings.sage_session_expired
export const getSagePayLastLogin = (state: RootState) => state.settings.sage_pay_last_login
export const getThirdPartyStepsCompleted = (state: RootState) => state.settings.third_party_steps_completed
