import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SettingsI } from './types'
import { MONTHS, BUDGET_BASED } from '../../resources/constants'
import { transformMonths } from '../../resources/functions';

export const initialState: SettingsI = {
  date_time: "",
  logo: '',
  /*accouting: {
    first_month_of_fiscal_year: null,
    first_month_of_income_tax_year: null,
    budget_based_on: null
  },
  sales: {
    state_tax_account_id: null
  },*/
  accouting: {
    transaction_activity_status: 0,
    bank_account_count: 0,
    gross_sale_account_id: null,
    user_id: null,
    location_branch_id: null,
    sales_activity_count: 0,
    state_tax_account_id: null,
    is_beginning_balance_exists: 0,
    is_coa_modified: 0,
    opening_balance_created_at: null,
    opening_balance_skipped_at: null
  },
  primary_sales_account: {
    name: "",
    account: null,
    description: ""
  },
  primary_state_tax_account: {
    name: "",
    account: null,
    description: ""
  },
  qbo_session_expired: true,
  qbo_last_login: null,

  sage_session_expired: true,
  sage_pay_last_login: null,
  third_party_steps_completed: 0,

  bank_account_match: true,
  opening_balance_exists: false,
  budget_settings: {
    budget_based_on: null,
    first_month_of_fiscal_year: null,
    first_month_of_income_tax_year: null,
    user_id: null
  },
  system_access: true,
  fisrtLoginCheck: 0
}

const selectedValue = (source: any[], value: number) => {
  const data = source.filter((record: any) => +record['value'] === value)
  return data.length && data[0] || null;
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateSettings(state, action: PayloadAction<any>) {
      state.accouting = {
        transaction_activity_status: action.payload.user_account_settings.transaction_activity_status || 0,
        bank_account_count: action.payload.user_account_settings.bank_account_count || 0,
        gross_sale_account_id: action.payload.user_account_settings.gross_sale_account_id,
        user_id: action.payload.user_account_settings.user_id,
        location_branch_id: action.payload.user_account_settings.location_branch_id,
        sales_activity_count: action.payload.user_account_settings.sales_activity_count || 0,
        state_tax_account_id: action.payload.user_account_settings.state_tax_account_id,
        is_beginning_balance_exists: action.payload.user_account_settings.is_beginning_balance_exists,
        is_coa_modified: action.payload.user_account_settings.is_coa_modified,
        opening_balance_created_at: action.payload.opening_balance_created_at,
        opening_balance_skipped_at: action.payload.opening_balance_skipped_at
      }
      state.primary_sales_account = action.payload.primary_sales_account
      state.primary_state_tax_account = action.payload.primary_state_tax_account
      state.bank_account_match = action.payload.bank_account_match
      state.opening_balance_exists = action.payload.opening_balance_exists || false
      state.budget_settings = {
        budget_based_on: selectedValue(BUDGET_BASED, action.payload.budget_settings.budget_based_on),
        first_month_of_fiscal_year: selectedValue(transformMonths(), action.payload.budget_settings.first_month_of_fiscal_year),
        first_month_of_income_tax_year: selectedValue(transformMonths(), action.payload.budget_settings.first_month_of_income_tax_year),
        user_id: action.payload.budget_settings.user_id
      },
      state.qbo_session_expired = !!action.payload.user_account_settings.qbo_session_expired,
      state.qbo_last_login = action.payload.user_account_settings.qbo_last_login,
      state.third_party_steps_completed = action.payload.user_account_settings.third_party_steps_completed,
      state.sage_session_expired = !!action.payload.user_account_settings.sage_session_expired,
      state.sage_pay_last_login = action.payload.user_account_settings.sage_pay_last_login || null,

      state.fisrtLoginCheck = action.payload.user_account_settings.transaction_activity_status ? action.payload.user_account_settings.transaction_activity_status : 0
    },
    updateQuickBookSettings(state, action: PayloadAction<any>) {
      state.qbo_session_expired = !!action.payload.user_account_settings.qbo_session_expired,
      state.qbo_last_login = action.payload.user_account_settings.qbo_last_login,
      state.third_party_steps_completed = action.payload.user_account_settings.third_party_steps_completed
    },
    updateOpeningBalanceExists(state, action: PayloadAction<any>) {
      state.opening_balance_exists = action.payload.opening_balance_exists || false
    }
    /*updateAccounting(state, action: PayloadAction<any>) {
      state.accouting.first_month_of_fiscal_year = selectedValue(transformMonths(), action.payload.first_month_of_fiscal_year)
      state.accouting.first_month_of_income_tax_year = selectedValue(transformMonths(), action.payload.first_month_of_income_tax_year)
      state.accouting.budget_based_on = selectedValue(BUDGET_BASED, action.payload.budget_based_on)
    },
    updateAccountingSales(state, action: PayloadAction<any>) {
      //console.log(action.payload);
      state.sales.state_tax_account_id = action.payload
    },*/
  },
})

export const { reducer } = settingsSlice

export const { updateSettings, updateOpeningBalanceExists, updateQuickBookSettings /*updateAccounting, updateAccountingSales*/ } = settingsSlice.actions
