import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Link, useParams, useNavigate } from 'react-router-dom'
import filterSolidIcon from '../../../assets/images/filter-solid-icon.svg';
import toast from 'react-hot-toast';
import { Button } from 'react-bootstrap';

import "react-datepicker/dist/react-datepicker.css";
import { ExpenseGlClassificationFormI } from '../../../resources/form-props';
import { BankSearchFormI } from '../../../resources/form-props';
import SAlert from '../../../components/SAlert';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import useDebounce from '../../../resources/hooks/UseDebounce';
import { glClassificationApi } from '../../../resources/hooks/api/glClassificationApi';
import { GLSearchFormValidate, BankExpenseValidate } from '../../../resources/form-validator';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { getBranch } from '../../../store/user/selectors'

import '../GlClassification.css'
import { GL_VP, MONTHS, SELECT_CUSTOM_STYLE, URLS } from '../../../resources/constants';
import { calculateMaxHeightContent, lastYears, usdCurrencyFormat } from '../../../resources/functions';
import SelectCustomOption from '../../../components/SelectCustomOption';
import SplitModal from '../../../components/Modal/ExpenseClassification/SplitModal'; // Import your new SplitModal component
import { SelectInstance } from 'react-select'; // Import the correct type
import ChartAccountModal from '../../../components/Modal/ChartOfAccount/ChartAccountModal';

interface Props {

}

const ExpenseGlClassification: React.FC<Props> = () => {

    const { glTransactionlist, bankGlUpdate } = glClassificationApi();
    const { glAccountsList } = useChartAccountApi();
    const urlParams = useParams();
    const navigate = useNavigate();
    const branchData = useSelector(getBranch)

    const [records, setRecords] = useState<any>([]);
    //const [glAccount, setGlAccount] = useState<any[]>([]);
    const [glDebitAccount, setGlDebitAccount] = useState<any[]>([]);
    const [glCreditAccount, setGlCreditAccount] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showCOAModal, setShowCOAModal] = useState<boolean>(false)
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0)
    const [selectedCalendar, setSelectedCalendar] = useState<any>({
        year: 2024,
        month: 1
    });
    const [showSplitModal, setShowSplitModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);


    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#d1ffbd'));
    const checkAllRef = useRef<HTMLInputElement>(null);
    const selectAllRef = useRef<HTMLInputElement[]>([]);

    const { register, trigger, control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm<ExpenseGlClassificationFormI>({
        defaultValues: {
            banks: []
        },
      resolver: yupResolver(BankExpenseValidate)
    });
    const { fields, append: formAppend, remove: formRemove } = useFieldArray({
        control,
        name: "banks"

    });

    const { control: control2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm<BankSearchFormI>({
        defaultValues: {
            month: {
                value: '01',
                label: 'January'
            },
            year: { value: '2024', label: '2024' },
        },
        resolver: yupResolver(GLSearchFormValidate)
    });

    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(131));
    })
    useEffect(() => {
        fetchTransactionList();
        fechDebitAccountList().then(r => {
            setGlDebitAccount(r);
        })
        fechCreditAccountList().then(r => {
            setGlCreditAccount(r);
        })
    }, [])
    // useEffect(() => {
    //     fetchTransactionList();
    // }, [selectedCalendar])
    useEffect(() => {
        records.map((record: any, index: number) => {
            const creditData = record['breakdowns'].filter((e: any) => e['account_type'] == "Credit_Deposit");
            const debitData = record['breakdowns'].filter((e: any) => e['account_type'] == "Debit_Deposit");
            /*const equityData = record['breakdowns'].filter((e: any) => e['account_type'] == "Equity");
            const bankTransferData = record['breakdowns'].filter((e: any) => e['account_type'] == "Bank Transfer");
            const otherData = record['breakdowns'].filter((e: any) => e['account_type'] == "Others");
            const adjustedBankDepositData = record['breakdowns'].filter((e: any) => e['account_type'] == "Adjusted Bank Deposit");
            */

            formAppend({
                formIndex: index,
                checked: false,
                record_id: record['id'],
                parent_id: record['parent_id'],
                description: record['description'],
                account_name: record['account_name'],
                transaction_at: record['transaction_at'],
                deposit: creditData.length && creditData[0]['account_value'] || 0,
                deposit_debit_account_id: debitData.length && debitData[0]['account_chart'] && {
                    label: debitData[0]['account_chart']['name'] + '(' + debitData[0]['account_chart']['account'] + ')',
                    value: debitData[0]['account_chart']['id']
                } || null,
                deposit_credit_account_id: creditData.length && creditData[0]['account_chart'] && {
                    label: creditData[0]['account_chart']['name'] + '(' + creditData[0]['account_chart']['account'] + ')',
                    value: creditData[0]['account_chart']['id']
                } || null,
                split : [],
            })
        })
    }, [records])
    useEffect(() => {
        if (fields.length == 0) {
            if (checkAllRef.current) {
                checkAllRef.current.checked = false;
            }
        }
    }, [fields])


    const fetchTransactionList = () => {
        const params: any = {
            "page": 1,
            "per_page": 500,
            year: urlParams['year'],
            month: urlParams['month'],
            branch_id: +branchData['id']
        }

        params['id'] = 0;
        params['account_type'] = 1;

        setIsLoading(true);
        glTransactionlist(params, (message: string, resp: any) => {
            reset();
            setRecords(resp.data.data.result);
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }
    // console.log(records);
    // const fechAccountList = (searchText: string = ''): Promise<any[]> => {
    //     return new Promise<any>((resolve, reject) => {
    //         const params: any = {
    //             "page": 1,
    //             "per_page": 10
    //         }
    //         if ( searchText ) {
    //             params['name'] = searchText;
    //         }
    //         glAccountsList(params, (message: string, resp: any) => {
    //             console.log(resp);
    //             const data = resp.data.data.result.map((e: any) => ({
    //                 label: e['name'] + `(${e['account']})`,
    //                 value: e['id']
    //             }));
    //             return resolve(data);
    //             //console.log(resp);
    //             // setGlAccount(data);
    //         }, (message: string, resp: any) => {
    //             toast.error(message);
    //             return resolve([]);
    //         })
    //     })
    // }

    const fechDebitAccountList = (searchText: string = ''): Promise<any[]> => {

        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            // params['parent_account_not_in'] = GL_VP.BANK_ACCOUNT.account.join(',');
            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                return resolve(data);
                //console.log(data);
                // setGlAccount(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }

    const fechCreditAccountList = (searchText: string = ''): Promise<any[]> => {
        return new Promise<any>((resolve1, reject1) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            // params['parent_account_in'] = GL_VP.BANK_ACCOUNT.account.join(',');
            glAccountsList(params, (message: string, resp: any) => {

                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                return resolve1(data);
                //console.log(resp);
                // setGlAccount(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve1([]);
            })
        })
    }

    // const filterAccountList = async (inputValue: string) => {
    //     const data = await fechAccountList(inputValue)
    //     return data;
    // };

    const filterDebitAccountList = async (inputValue: string) => {
        const data = await fechDebitAccountList(inputValue)
        return data;
    };
    const filterCreditAccountList = async (inputValue: string) => {
        const data = await fechCreditAccountList(inputValue)
        return data;
    };


    const handleCheckAllChange = (e: any) => {
        if (checkAllRef.current) {
            checkAllRef.current.checked = e.target.checked;

            fields.map((record: any, index: number) => {
                setValue(`banks.${index}.checked`, e.target.checked)
            })

            /*records.map((record: any, index: number) => {
                setValue(`banks.${index}.checked`, e.target.checked)
            })*/
        }
        // setChecked(
        //     e.target.checked ? records.map((c: any) => c.id) : []
        // );
    };

    const handleCheckAll = (e: any, index: number) => {
        setValue(`banks.${index}.checked`, e.target.checked);
        if (e.target.checked) {
            const checkedRecords = fields.filter((record: any, index: number) => getValues(`banks.${index}.checked`) === true);

            if (checkedRecords.length == fields.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = true;
                }
            }
        } else {
            const uncheckedRecords = fields.filter((record: any, index: number) => getValues(`banks.${index}.checked`) === false);

            if (uncheckedRecords.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = false;
                }
            }
        }
    }

    // const handleSplitClick = (record: any, rowId : number) => {
    //     console.log(rowId)
    //     setSelectedRecord(record);
    //     setShowSplitModal(true);
    // };
    const [selectedRowID, setSelectedRowID] = useState<number | null>(null);
    const [indexNo, setIndexNo] = useState<number | null>(null);
    const handleSplitClick = (data: any, id: number) => {
        
    
        // Optionally find the specific item in the array and add the index if needed
        // For simplicity, we'll just push the index to the end of the array
        
        setSelectedRecord(data);
        setShowSplitModal(true);
    };
    const [splitData, setSplitData] = React.useState<any[]>([]);
    const handleSplitSave = (rowID: number, indexNo: number, splits: any[]) => {
        // Create new splits with the rowID added
    const newSplits = splits.map(split => ({ ...split }));
    
            // Update splitData state
            setSplitData(prevData => {
                // Filter out existing splits with the same rowID
                const updatedSplits = prevData.filter(split => split.rowID !== rowID);
                // Merge new splits with the updated data
                return [...updatedSplits, ...newSplits];
            });

            // Remove rowID from newSplits
            const finalSplits = newSplits.map(split => {
                const { rowID, ...rest } = split;
                return rest;
            });

            // Ensure setValue is called with the latest splitData
            setValue(`banks.${indexNo}.split`, finalSplits);

            
    };






    const processData = (data: any) => {
        //console.log(data);
        const toRemoveItems: number[] = [];

    const records = data['banks']
        .map((d: any, index: number) => {
            if (d['checked']) {
                toRemoveItems.push(index);

                let isFirstRow = true; // Track if it's the first row for setting id
                
                // // Filter and process splitData to handle multiple records
                // const splitDataForRecord = splitData
                //     .filter((split: any) => {
                //         if (split.rowID === d.record_id) {
                //             if (isFirstRow) {
                //                 isFirstRow = false;
                //                 return true; // Include the first entry
                //             } else {
                //                 split.id = null; // Set id to null for subsequent entries
                //                 return true;
                //             }
                //         }
                //         return false;
                //     })
                //     .map((split: any) => {
                //         const { rowID, ...rest } = split; // Remove rowID
                //         return {
                //             ...rest,
                //             id: rest.id, // Only first item retains id
                //             deposit_debit_account_id: rest.deposit_debit_account_id ? rest.deposit_debit_account_id.value : null,
                //             deposit_credit_account_id: rest.deposit_credit_account_id ? rest.deposit_credit_account_id : null,
                //         };
                //     });

                // Provide default split_data if no entries found
                // const split = splitDataForRecord.length > 0 ? splitDataForRecord : [
                //     {
                //         id: d.record_id, // Only first entry should have id, others should be null
                //         deposit: d.deposit,
                //         deposit_debit_account_id: d['deposit_debit_account_id'] && d['deposit_debit_account_id']['value'] ? +d['deposit_debit_account_id']['value'] : null,
                //         deposit_credit_account_id: d['deposit_credit_account_id'] && d['deposit_credit_account_id']['value'] ? +d['deposit_credit_account_id']['value'] : null,
                //     }
                // ].map((item, index) => ({
                //     ...item,
                //     id: index === 0 ? item.id : null // Ensure only the first item has an id
                // }));
                // Process the existing splitData within the record
                //let isFirstRow = true; // Track if it's the first row for setting id
                
                // Process the existing splitData within the record
                let split = d['split']
                    .map((split: any) => {
                        if (isFirstRow) {
                            isFirstRow = false;
                            return {
                                ...split,
                                id: split.id, // Only first item retains id
                                deposit_debit_account_id: split.deposit_debit_account_id ? split.deposit_debit_account_id.value : null,
                                deposit_credit_account_id: split.deposit_credit_account_id ? split.deposit_credit_account_id : null,
                            };
                        } else {
                            return {
                                ...split,
                                id: null, // Set id to null for subsequent entries
                                deposit_debit_account_id: split.deposit_debit_account_id ? split.deposit_debit_account_id.value : null,
                                deposit_credit_account_id: split.deposit_credit_account_id ? split.deposit_credit_account_id : null,
                            };
                        }
                    })
                    .filter((item: any) => item.deposit_debit_account_id !== null || item.deposit_credit_account_id !== null); // Filter out splits with null debit and credit account ids

                // If no valid split data found, add a default split entry
                if (split.length === 0) {
                    split = [
                        {
                            id: d.record_id, // Only first entry should have id, others should be null
                            deposit: d.deposit,
                            deposit_debit_account_id: d['deposit_debit_account_id'] && d['deposit_debit_account_id']['value'] ? +d['deposit_debit_account_id']['value'] : null,
                            deposit_credit_account_id: d['deposit_credit_account_id'] && d['deposit_credit_account_id']['value'] ? +d['deposit_credit_account_id']['value'] : null,
                        },
                    ];
                }

                // Only include records that have valid split entries
                if (split.length > 0) {
                return {
                    id: d.record_id,
                    parent_id: d.parent_id,
                    description: d.description,
                    account_name: d.account_name,
                    transaction_at: d.transaction_at,
                    split: split // Add split_data array here
                };
                } else {
                    return null;
                }
            } else {
                return null;
            }
        })
        .filter((e: any) => e); // Filter out null values
    
        // console.log(records)
        //setSplitData([]);
        //toRemoveItems.push(index);
         postData(records, toRemoveItems);
    };
    const postData = (records: any, toRemoveItems: number[]) => {
        if (records.length) {
            setIsLoading(true);
            bankGlUpdate({ records, branch_id: +branchData['id'] }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.success(message)
                formRemove(toRemoveItems)
                if (records.length == fields.length) {
                    navigate(-1);
                }
                // fetchTransactionList();
            }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.error(message);
            })
        } else {
            toast.error("Please select at least one record");
        }
    }
    const processSearchData = (data: any) => {
        setSelectedCalendar({
            year: +data['year']['value'],
            month: +data['month']['value']
        })
    }
    const handleSinglePost = (index: number) => {
        const data = getValues();
        const toRemoveItems: number[] = [];

        if (data['banks'][index]['checked']) {
            // Filter splitData based on the record_id
            const splitDataForRecord = splitData.filter((split: any) => split.id === data['banks'][index]['record_id']);

            const records = [data['banks'][index]]
                .map((d: any) => {
                    return {
                        id: d.record_id,
                        parent_id: d.parent_id,
                        description: d.description,
                        account_name: d.account_name,
                        transaction_at: d.transaction_at,
                        deposit: d.deposit,
                        deposit_debit_account_id: d['deposit_debit_account_id'] && d['deposit_debit_account_id']['value'] ? +d['deposit_debit_account_id']['value'] : null,
                        deposit_credit_account_id: d['deposit_credit_account_id'] && d['deposit_credit_account_id']['value'] ? +d['deposit_credit_account_id']['value'] : null,
                        split: splitDataForRecord // Add split_data array here
                    }
                });
            //console.log(records);

            toRemoveItems.push(index);

            postData(records, toRemoveItems);
        } else {
            toast.error("Please select the record");
        }
    }

    const handleFieldValidation = async (index: number) => {
        // Trigger validation for the specified field within the array
        const isValidated = await trigger(`banks.${index}`);
        if (isValidated) {
            handleSinglePost(index)
        }
    };


    const selectRefs = useRef<SelectInstance<any, false, any>[]>([]);

    const handleSelectChange = (index: number, e:any) => {
        setTimeout(() => {
            let nextIndex = index + 1;
            while (nextIndex < selectRefs.current.length) {
              const nextSelect = selectRefs.current[nextIndex];
              if (nextSelect && !nextSelect.props.isDisabled) {
                nextSelect.focus();
                break;
              }
              nextIndex++;
            }
          }, 0);
        
      };


    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Expense Classification</h1>
                        </div>
                        <div className="col-sm-6 justify-content-end align-items-center d-flex">
                            <Link to={URLS.EXPENSE_GL_CLASSIFICATION_STATUS} className="btn btn-link">Back To Classification Status</Link>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className="filter-btn">
                            <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                <div className="loader"></div>
                            </div>
                        </div>
                        <Form>
                            <div className="modal-footer">
                            {
                                fields.length > 0 && (
                                    <>
                                    <button type="button" className="btn btn-primary" onClick={handleSubmit(processData)}>Post</button>&nbsp;&nbsp;
                                    </>
                                ) || <></>
                            }
                                <button type="button" className="btn btn-secondary" onClick={() => setShowCOAModal(true)}>ADD COA ACCOUNT</button>
                            </div>

                            <br />
                            <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}>
                                <table className="table table-default">
                                    <thead>
                                        <tr className="align-middle">
                                            <th className="w-5 text-center">
                                                <div className="form-check">
                                                    <label>
                                                        <input ref={checkAllRef} className="form-check-input wh-20 checkAll" type="checkbox" onChange={handleCheckAllChange} />
                                                    </label>
                                                </div>
                                            </th>
                                            <th className="text-center">Date</th>
                                            <th className="text-start">Description</th>
                                            <th className="text-end">Expense</th>
                                            <th className="text-start">Debit GL Account</th>
                                            <th className="text-start">Credit GL Account</th>
                                            <th className="text-center">Action</th> {/* Added column for actions */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fields.length > 0 ?
                                                fields.map((data: any, index: any) => {
                                                    // Find split data for the current row
                                                    const hasSplitData = splitData.some((split: any) => split.id === data.record_id);
                                                    // Extract split debit account IDs
                                                    const splitDebitAccounts = splitData
                                                    .filter((split) => split.id === data.record_id)
                                                    .map((split) => split.deposit_debit_account_id.label)
                                                    .join(', ');

                                                    return (
                                                        <tr className="align-middle h-60" key={data['id']}>
                                                            <td className="text-center">
                                                                <div className="form-check">
                                                                    <label>
                                                                        <input className="form-check-input wh-20 checkbox" {...register(`banks.${index}.checked`)}
                                                                            name={`banks.${index}.checked`}
                                                                            defaultChecked={fields[index]['checked']}
                                                                            type="checkbox" onChange={(e: any) => handleCheckAll(e, index)} />
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td className="text-center"><strong>{moment(fields[index].transaction_at).format('MM-DD-YYYY h:mm:ss')}</strong></td>
                                                            <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={fields[index].description || 'NA'}>{fields[index].description || 'NA'}</td>
                                                            <td className="text-end">{usdCurrencyFormat(Math.abs(fields[index].deposit))}</td>
                                                            {
                                                                hasSplitData ? (
                                                                    <>
                                                                        <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={splitDebitAccounts || 'NA'}>
                                                                            {splitDebitAccounts}
                                                                        </td>
                                                                        <td className="text-start">
                                                                            <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`banks.${index}.deposit_credit_account_id`}
                                                                                    render={({ field: { value, onChange, onBlur } }) => (
                                                                                        <AsyncSelect
                                                                                            
                                                                                            isClearable={true}
                                                                                            placeholder={'Please select GL acc'}
                                                                                            defaultOptions={glCreditAccount}
                                                                                            loadOptions={filterCreditAccountList}
                                                                                            // onChange={(e:any) => {
                                                                                            //     onChange(e);
                                                                                            //     // Manually blur the input field to remove focus
                                                                                            //     const activeElement = document.activeElement as HTMLElement;
                                                                                            //     activeElement?.blur();
                                                                                            //   }}
                                                                                            onChange={(e: any) => {
                                                                                                onChange(e);
                                                                                                handleSelectChange(index,e);
                                                                                              }}
                                                                                            className='vp-dropdown'
                                                                                            menuPosition='fixed'
                                                                                            value={value}
                                                                                            defaultValue={value}
                                                                                            styles={selectCustomStyle}
                                                                                            components={{ Option: SelectCustomOption }}
                                                                                            isDisabled={!!fields[index].deposit_credit_account_id}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                {
                                                                                    !!fields[index].deposit_credit_account_id && <input {...register(`banks.${index}.deposit_credit_account_id`)} type="hidden" />
                                                                                }
                                                                            </Form.Group>
                                                                            {
                                                                                errors && errors.banks && errors.banks[index] && errors.banks[index]?.deposit_credit_account_id && errors.banks[index]?.deposit_credit_account_id?.message && (
                                                                                    <Form.Text className="text-danger d-flex">
                                                                                        <strong>
                                                                                            {errors.banks[index]?.deposit_credit_account_id?.message as ReactNode || ''}
                                                                                        </strong>
                                                                                    </Form.Text>
                                                                                ) || ''
                                                                            }
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <td className="text-start">
                                                                            <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                                <Controller
                                                                                    control={control}
                                                                                    disabled={!fields[index].deposit}
                                                                                    name={`banks.${index}.deposit_debit_account_id`}
                                                                                    render={({ field: { value, onChange, disabled } }) => (
                                                                                        <AsyncSelect
                                                                                        ref={(el) => (selectRefs.current[index] = el as SelectInstance<any, false, any>)}
                                                                                            isClearable={true}
                                                                                            placeholder={'Please select GL acc'}
                                                                                            defaultOptions={glDebitAccount}
                                                                                            loadOptions={filterDebitAccountList}
                                                                                            onChange={(e: any) => {
                                                                                                onChange(e);
                                                                                                handleSelectChange(index,e);
                                                                                              }}
                                                                                            className='vp-dropdown'
                                                                                            menuPosition='fixed'
                                                                                            value={value}
                                                                                            defaultValue={value}
                                                                                            isDisabled={disabled}
                                                                                            styles={selectCustomStyle}
                                                                                            components={{ Option: SelectCustomOption }}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </Form.Group>
                                                                            {
                                                                                errors && errors.banks && errors.banks[index] && errors.banks[index]?.deposit_debit_account_id && errors.banks[index]?.deposit_debit_account_id?.message && (
                                                                                    <Form.Text className="text-danger d-flex">
                                                                                        <strong>
                                                                                            {errors.banks[index]?.deposit_debit_account_id?.message as ReactNode || ''}
                                                                                        </strong>
                                                                                    </Form.Text>
                                                                                ) || ''
                                                                            }
                                                                        </td>
                                                                        <td className="text-start">
                                                                            <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`banks.${index}.deposit_credit_account_id`}
                                                                                    render={({ field: { value, onChange, onBlur } }) => (
                                                                                        <AsyncSelect
                                                                                            isClearable={true}
                                                                                            placeholder={'Please select GL acc'}
                                                                                            defaultOptions={glCreditAccount}
                                                                                            loadOptions={filterCreditAccountList}
                                                                                            onChange={(e:any) => {
                                                                                                onChange(e);
                                                                                                // Manually blur the input field to remove focus
                                                                                                const activeElement = document.activeElement as HTMLElement;
                                                                                                activeElement?.blur();
                                                                                              }}
                                                                                            className='vp-dropdown'
                                                                                            menuPosition='fixed'
                                                                                            value={value}
                                                                                            defaultValue={value}
                                                                                            styles={selectCustomStyle}
                                                                                            components={{ Option: SelectCustomOption }}
                                                                                            isDisabled={!!fields[index].deposit_credit_account_id}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                {
                                                                                    !!fields[index].deposit_credit_account_id && <input {...register(`banks.${index}.deposit_credit_account_id`)} type="hidden" />
                                                                                }
                                                                            </Form.Group>
                                                                            {
                                                                                errors && errors.banks && errors.banks[index] && errors.banks[index]?.deposit_credit_account_id && errors.banks[index]?.deposit_credit_account_id?.message && (
                                                                                    <Form.Text className="text-danger d-flex">
                                                                                        <strong>
                                                                                            {errors.banks[index]?.deposit_credit_account_id?.message as ReactNode || ''}
                                                                                        </strong>
                                                                                    </Form.Text>
                                                                                ) || ''
                                                                            }
                                                                        </td>
                                                                    </>
                                                                )
                                                            }
                                                            <td className="text-center">
                                                                <Button variant="primary" onClick={() => handleSplitClick(data, fields[index].record_id)}>{ hasSplitData ? 'Modify Split' : 'Split Classification' }</Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                }) :
                                                <tr>
                                                    <td colSpan={7} className='text-center'>No data found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

            <SplitModal
                rowID={selectedRecord?.record_id || 0}
                indexNo={selectedRecord?.formIndex || 0}
                show={showSplitModal}
                onHide={() => setShowSplitModal(false)}
                onSave={handleSplitSave}
                originalAmount={selectedRecord?.deposit || 0}
                glDebitAccounts={glDebitAccount}
                creditAccountName={selectedRecord?.deposit_credit_account_id?.label || ''}
                creditAccountId={selectedRecord?.deposit_credit_account_id?.value || ''}
                transactionAt={selectedRecord?.transaction_at || ''}
                description={selectedRecord?.description || ''}
                splitData={splitData}
                filterDebitAccountList={filterDebitAccountList}
                
            />
                
            {
                showCOAModal && <ChartAccountModal closeModal={() => setShowCOAModal(false)} addRecord={true} record={null} /> || <></>
            }
            
        </div>
    )
}

export default ExpenseGlClassification;