import React, { useEffect, useState, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getQboSession, getQboLastLogin, getThirdPartyStepsCompleted } from '../../../store/settings/selectors'
import { URLS } from '../../../resources/constants';
import moment from 'moment';
import { thirdPartyApi } from '../../../resources/hooks/api/thirdPartyApiHook';
import { getBranch } from '../../../store/user/selectors'
import toast from 'react-hot-toast';

const QuickBookSteps = [{
    label: 'Import Chart of Accounts',
    value: 1
}, {
    label: 'Import Balance Sheet & Profit Loss Statement',
    value: 2
}, {
    label: 'Import Beginning Balance',
    value: 3
}, {
    label: 'Import Vendor Data (Optional)',
    value: 4
}, {
    label: 'Import Customer Center Data (Optional)',
    value: 5
}];

interface Props { }

const Dashboard: React.FC<Props> = () => {

    const navigate = useNavigate();
    
    const quickBookSession = useSelector(getQboSession);
    const quickBookLastLogin = useSelector(getQboLastLogin);
    const quickBookStepCompleted = useSelector(getThirdPartyStepsCompleted);

    const branchData = useSelector(getBranch);

    const { quickBookImportData } = thirdPartyApi();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if ( quickBookSession ) {
            navigate(URLS.THIRD_PARTY_CONNECTION)
        }
    }, [])

    const _handleQuickBookImportData = (step: number) => {
        setIsLoading(true)
        quickBookImportData({branch_id: +branchData['id'], step}, (message: string, resp: any) => {
            setIsLoading(false);
            toast.success(message);
            if ( step == 1 ) {
                navigate(URLS.QUICKBOOK_COA)
            }
            
        }, (message: string, resp: any) => {
            setIsLoading(false)
            console.log(resp)
            toast.error(message)
        })
    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-12 align-items-center d-flex justify-content-between">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">
                                Integration
                            </h1>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className='d-flex gap-1'>
                            <div className='border-end d-flex flex-column gap-3 pe-5 w-50'>
                                <h6>You are connected with Quick Books Online App</h6>
                                <div className='d-flex gap-4'>
                                    <div className='border' style={{ borderRadius: '5px' }}>
                                        <div className='align-items-center d-flex justify-content-center' style={{ backgroundColor: '#D9D9D9', borderTopRightRadius: '5px', borderTopLeftRadius: '5px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px' }}>
                                            <img src="quick-book.svg" alt="" className='h-60' />
                                        </div>
                                        <div className='align-items-center d-flex gap-1 justify-content-center pb-1 pt-1'>
                                            <label>Quick Books</label>
                                        </div>
                                    </div>
                                    <span>Connected On: {quickBookLastLogin ? moment(quickBookLastLogin).format('Do MMM YYYY') : 'NA'}</span>
                                </div>
                            </div>
                            <div className='ps-4 w-100'>
                                <h6>Connection Wizard</h6>
                                <div className='d-flex flex-column gap-2 p-4'>
                                    {QuickBookSteps.map((step, index) => {
                                        return (
                                            <div key={index} className='d-flex gap-2 align-items-center'>
                                                <input type="checkbox" id={`step-${index}`} checked={ step.value <= +quickBookStepCompleted } disabled />
                                                <label htmlFor={`step-${index}`} className='mb-0'>{step.label}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="row mt-md-5">
                                    <div className="col-md-12 d-flex justify-content-end">
                                        <Link to={"javascript:void(0)"} className="btn btn-primary" onClick={() => _handleQuickBookImportData(+quickBookStepCompleted + 1)}>
                                            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <span>Continue</span>}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
