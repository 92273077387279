import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Link, useParams, useNavigate } from 'react-router-dom'
import filterSolidIcon from '../../../assets/images/filter-solid-icon.svg';
import toast from 'react-hot-toast';
import { Button } from 'react-bootstrap';

import "react-datepicker/dist/react-datepicker.css";
import { QuickBookCOAClassificationFormI } from '../../../resources/form-props';
import { BankSearchFormI } from '../../../resources/form-props';
import SAlert from '../../../components/SAlert';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import useDebounce from '../../../resources/hooks/UseDebounce';
import { glClassificationApi } from '../../../resources/hooks/api/glClassificationApi';
import { GLSearchFormValidate, QuickBookCOAValidate } from '../../../resources/form-validator';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { getBranch } from '../../../store/user/selectors'

// import '../GlClassification.css'
import { GL_VP, SELECT_CUSTOM_STYLE, URLS } from '../../../resources/constants';
import { calculateMaxHeightContent, usdCurrencyFormat } from '../../../resources/functions';
import SelectCustomOption from '../../../components/SelectCustomOption';
import { SelectInstance } from 'react-select'; // Import the correct type
import { thirdPartyApi } from '../../../resources/hooks/api/thirdPartyApiHook';

interface Props {

}

const QB_CLASSIFICATION = {
    Liability: [4002, 4050, 4200, 4300, 5001],
    Asset: [1002, 1014, 1151, 1200, 1946, 2001, 3000],
    Expense: [8000, 8006, 8021, 8199, 8600],
    Revenue: [6001, 6100, 6700],
    Equity: [5200]
}

type ClassificationType = 'Liability' | 'Asset' | 'Expense' | 'Revenue' | 'Equity';

interface DefaultOptionI {
    [key: string]: any[]
}

const QBCOAClassification: React.FC<Props> = () => {

    const { fetchQuickBookAccountCharts, postQuickBookAccountCharts } = thirdPartyApi();
    const { glTransactionlist, bankGlUpdate } = glClassificationApi();
    const { glAccountsList } = useChartAccountApi();
    const navigate = useNavigate();
    const branchData = useSelector(getBranch)

    const [records, setRecords] = useState<any>([]);
    const [glDebitAccount, setGlDebitAccount] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0);
    const [dropdownOptions, setDropdownOptions] = useState<DefaultOptionI>(Object.keys(QB_CLASSIFICATION).reduce((a: any, c: string) => ({
        ...a,
        [c]: []
    }), {}));

    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#d1ffbd'));
    const checkAllRef = useRef<HTMLInputElement>(null);
    const selectAllRef = useRef<HTMLInputElement[]>([]);

    const { register, trigger, control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm<QuickBookCOAClassificationFormI>({
        defaultValues: {
            coa: []
        },
      resolver: yupResolver(QuickBookCOAValidate)
    });
    const { fields, append: formAppend, remove: formRemove } = useFieldArray({
        control,
        name: "coa"

    });

    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(131));
    })
    useEffect(() => {
        fetchCAList();
    }, [])

    useEffect(() => {
        records.map((record: any, index: number) => {
            formAppend({
                formIndex: index,
                checked: false,
                record_id: record['id'],
                name: record['NAME'],
                accountType: record['AccountType'],
                classification: record['Classification'],
                account_id: record['account_chart'] ? {
                    label: record['account_chart']['name'] + `(${record['account_chart']['account']})`,
                    value: record['account_chart']['id']
                } : null,
                acc_num: record['AcctNum']
            })
        })
    }, [records])
    /*useEffect(() => {
        if (fields.length == 0) {
            if (checkAllRef.current) {
                checkAllRef.current.checked = false;
            }
        }
    }, [fields])*/


    const fetchCAList = () => {
        const params: any = {
            "page": 1,
            "per_page": 500,
            branch_id: +branchData['id']
        }

        setIsLoading(true);
        fetchQuickBookAccountCharts(params, (message: string, resp: any) => {
            reset();
            setRecords(resp.data.data.result);
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }

    const fechAccountList = (searchText: string = '', classification: ClassificationType = 'Liability'): Promise<any[]> => {
        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            
            // params['parent_account_in'] = QB_CLASSIFICATION[classification].join(',');
            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                return resolve(data);
                //console.log(data);
                // setGlAccount(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }

    const filterAccountList = async (inputValue: string, item: any) => {
        console.log('filterAccountList', inputValue, item);
        const data = await fechAccountList(inputValue, item['classification']);
        return data;
    };

    const fetchDefaultOptions = () => {
        Object.keys(QB_CLASSIFICATION).map((key: string) => {
            fechAccountList('', key as ClassificationType).then((data: any) => {
                setDropdownOptions((prev: any) => ({
                    ...prev,
                    [key]: data
                }))
            })
        })
    }


    const handleCheckAllChange = (e: any) => {
        if (checkAllRef.current) {
            checkAllRef.current.checked = e.target.checked;

            fields.map((record: any, index: number) => {
                setValue(`coa.${index}.checked`, e.target.checked)
            })

            /*records.map((record: any, index: number) => {
                setValue(`banks.${index}.checked`, e.target.checked)
            })*/
        }
        // setChecked(
        //     e.target.checked ? records.map((c: any) => c.id) : []
        // );
    };

    const handleCheckAll = (e: any, index: number) => {
        setValue(`coa.${index}.checked`, e.target.checked);
        if (e.target.checked) {
            const checkedRecords = fields.filter((record: any, index: number) => getValues(`coa.${index}.checked`) === true);

            if (checkedRecords.length == fields.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = true;
                }
            }
        } else {
            const uncheckedRecords = fields.filter((record: any, index: number) => getValues(`coa.${index}.checked`) === false);

            if (uncheckedRecords.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = false;
                }
            }
        }
    }

    const processData = (data: any) => {
        //console.log(data);
        const toRemoveItems: number[] = [];

        // console.log('COA data', data['coa']);

        const records = data['coa']
            .map((d: any, index: number) => {
                // if (d['checked']) {
                    toRemoveItems.push(index);
                    return {
                        account_id: +d.account_id.value,
                        acc_num: d.acc_num,
                        // parent_id: d.parent_id,
                        // description: d.description,
                        name: d.name
                        // transaction_at: d.transaction_at
                    };
                // } else {
                //     return null;
                // }
            })
            .filter((e: any) => e); // Filter out null values
            postData(records, toRemoveItems);
    }

    const postData = (records: any, toRemoveItems: number[]) => {
        if (records.length) {
            setIsLoading(true);
            postQuickBookAccountCharts({ records, branch_id: +branchData['id'] }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.success(message)
                formRemove(toRemoveItems)
                if (records.length == fields.length) {
                    navigate(-1);
                }
                // fetchCAList();
            }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.error(message);
            })
        } else {
            toast.error("Please select at least one record");
        }
    }

    const selectRefs = useRef<SelectInstance<any, false, any>[]>([]);

    const handleSelectChange = (index: number, e:any) => {
        setTimeout(() => {
            let nextIndex = index + 1;
            while (nextIndex < selectRefs.current.length) {
              const nextSelect = selectRefs.current[nextIndex];
              if (nextSelect && !nextSelect.props.isDisabled) {
                nextSelect.focus();
                break;
              }
              nextIndex++;
            }
          }, 0);
      };

    useEffect(() => {
        fetchDefaultOptions();
    }, [])


    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">COA Classification</h1>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className="filter-btn">
                            <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                <div className="loader"></div>
                            </div>
                        </div>
                        <Form>
                            <div 
                            className="table-responsive list-table table-scroll" 
                            style={{ maxHeight: contentMaxHeight }}
                            >
                                <table className="table table-default">
                                    <thead>
                                        <tr className="align-middle">
                                            {/* <th className="w-5 text-center">
                                                <div className="form-check">
                                                    <label>
                                                        <input ref={checkAllRef} className="form-check-input wh-20 checkAll" type="checkbox" onChange={handleCheckAllChange} />
                                                    </label>
                                                </div>
                                            </th> */}
                                            <th className="text-start">Name</th>
                                            <th className="text-start">Account Type</th>
                                            <th className="text-start">Classification</th>
                                            <th className="text-start">GL Account</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fields.length > 0 ?
                                                fields.map((data: any, index: any) => {
                                                    return (
                                                        <tr className="align-middle h-60" key={data['id']}>
                                                            {/* <td className="text-center">
                                                                <div className="form-check">
                                                                    <label>
                                                                        <input className="form-check-input wh-20 checkbox" {...register(`coa.${index}.checked`)}
                                                                            defaultChecked={fields[index]['checked']}
                                                                            type="checkbox" onChange={(e: any) => handleCheckAll(e, index)} />
                                                                    </label>
                                                                </div>
                                                            </td> */}
                                                            <td className="text-start"><strong>{fields[index].name}</strong></td>
                                                            <td className="text-start text-truncate" style={{ maxWidth: '250px' }}>{fields[index].accountType || 'NA'}</td>
                                                            <td className="text-start">{fields[index].classification || 'NA'}</td>
                                                            <td className="text-start">
                                                                <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                    <Controller
                                                                        control={control}
                                                                        name={`coa.${index}.account_id`}
                                                                        render={({ field: { value, onChange } }) => (
                                                                            <AsyncSelect
                                                                                ref={(el) => (selectRefs.current[index] = el as SelectInstance<any, false, any>)}
                                                                                isClearable={true}
                                                                                placeholder={'Please select GL acc'}
                                                                                defaultOptions={dropdownOptions[fields[index].classification]}
                                                                                loadOptions={(inputValue: string) => filterAccountList(inputValue, fields[index])}
                                                                                onChange={(e: any) => {
                                                                                    onChange(e);
                                                                                    handleSelectChange(index,e);
                                                                                }}
                                                                                className='vp-dropdown'
                                                                                menuPosition='fixed'
                                                                                value={value}
                                                                                defaultValue={value}
                                                                                styles={selectCustomStyle}
                                                                                components={{ Option: SelectCustomOption }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Form.Group>
                                                                {
                                                                    errors && errors.coa && errors.coa[index] && errors.coa[index]?.account_id && errors.coa[index]?.account_id?.message && (
                                                                        <Form.Text className="text-danger d-flex">
                                                                            <strong>
                                                                                {errors.coa[index]?.account_id?.message as ReactNode || ''}
                                                                            </strong>
                                                                        </Form.Text>
                                                                    ) || ''
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                }) :
                                                <tr>
                                                    <td colSpan={7} className='text-center'>No data found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='text-end mt-3'>
                            {
                                fields.length > 0 && (
                                    <button type="button" className="btn btn-primary" onClick={handleSubmit(processData)}>
                                        Verify & Overwrite COA
                                    </button>
                                ) || <></>
                            }
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QBCOAClassification;