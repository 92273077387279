import React, { useEffect, useState, ReactNode } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useSearchParams, useParams, useNavigate } from 'react-router-dom'
import { getBranch } from '../../store/user/selectors'

import { thirdPartyApi } from '../../resources/hooks/api/thirdPartyApiHook'

interface Props { }

const QuickBookCallback: React.FC<Props> = () => {

    const { quickBookCallback } = thirdPartyApi();

    const dispatch = useDispatch();
    const branchData = useSelector(getBranch)

    const [searchParams, setSearchParams] = useSearchParams();

    const _handleRetriveRecord = (code: string, state: string, realmId: string) => {
        const params = {
            code: code,
            state: state,
            realmId: realmId,
            branch_id: +branchData['id'],
        }
        quickBookCallback(params, (message: string, resp: any) => {
            window.close();
            // console.log('quick book callback: ', resp)
        }, (message: string, resp: any) => {
            console.log(resp)
        })
    }

    useEffect(() => {
        const code: string = searchParams.get('code') ? searchParams.get('code')! : '';
        const state: string = searchParams.get('state') ? searchParams.get('state')! : '';
        const realmId: string = searchParams.get('realmId') ? searchParams.get('realmId')! : '';

        _handleRetriveRecord(code, state, realmId);
    }, [ searchParams ])

    useEffect(() => {
        window.addEventListener('message', (event) => {
            /*dispatch(updateQuickBookSettings({
                user_account_settings: {
                    qbo_session_expired: false,
                    qbo_last_login: new Date().toISOString(),
                    third_party_steps_completed: 0
                }
            }));*/
            window.close();
            // if (event.data === 'success') {
            //     console.log('quick book success message: ', event)
            // } else if (event.data === 'error') {
            //     console.log('quick book error message: ', event)
            // }
        });

        // Cleanup event listener when component unmounts
        return () => {
            window.removeEventListener("message", (event) => {
                console.log('quick book message: ', event)
            });
        };
    }, [])
    return (
        <div className="main-container flex-grow-1">
        </div>
    )
}

export default QuickBookCallback
