
import { call, put } from "redux-saga/effects";
import { updateUser, authenticateUser,updateAccessToken } from "../store/user";
import { API_URLS } from "../resources/constants";
import { formQueryString } from '../resources/functions'
import {CallApi} from './callApi'

export function* auth(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.QUICK_BOOK.AUTH + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* callback(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.QUICK_BOOK.CALLBACK + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* importDate(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.QUICK_BOOK.IMPORT_DATA + '?' + formQueryString(payload)
    let result = yield call(CallApi.POST, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* fetchAccountChart(action: any): any {
  try {
    const payload = action.payload;
    const url = API_URLS.QUICK_BOOK.FETCH_ACCOUNT_CHARTS + '?' + formQueryString(payload)
    let result = yield call(CallApi.GET, url,{},true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}

export function* postAccountChart(action: any): any {
  try {
    const { records, branch_id } = action.payload;
    const url = API_URLS.QUICK_BOOK.POST_ACCOUNT_CHARTS + '?branch_id=' + branch_id;
    let result = yield call(CallApi.POST, url, records, true);
    //yield put(updateUser(result.data));
    action && action.callbackSuccess(result);
  } catch (e) {
    action && action.callbackError(e)
  }
}