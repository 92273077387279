import { FC, useEffect, useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { useChartAccountApi } from '../../resources/hooks/api/chartAccountApiHook'
import './tree.css'

import { getBranch } from '../../store/user/selectors'

interface Props {
  record: any
  handleEditRecord: (id: number) => void
  isExpandAll: boolean
}
const CaRecod: FC<Props> = ({ record, handleEditRecord, isExpandAll }) => {
  const [subRecords, setSubRecords] = useState<any[]>([])
  const [showSubRecords, setShowSubRecords] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(false)

  const branchData = useSelector(getBranch)

  const { getList } = useChartAccountApi()

  useEffect(() => {
    setShowSubRecords(isExpandAll)
  }, [isExpandAll])

  const getSubVal = (e: any) => {
    const params = {
      page: 1,
      per_page: 100,
      id: e.id,
      branch_id: +branchData['id'],
    }
    setShowLoader(true)
    getList(
      params,
      (message: string, resp: any) => {
        setSubRecords(resp.data.data.result)
        setShowLoader(false)
      },
      (message: string, resp: any) => {
        setShowLoader(false)
        toast.error(message)
      }
    )
  }

  const handleClick = (e: any) => {
    setShowSubRecords(!showSubRecords)
    // if (!showSubRecords) {
    //     getSubVal(e);
    // }
  }

  const _handleUpdate = (id: number) => {
    handleEditRecord(id)
  }

  return (
    <>
      <div className="person">
        <div className={`name p-2 col-md-12`} >
          {showLoader && <Spinner animation="border" size="sm" />}
          {
            ['VP', 'SST', 'GT', 'ST', 'CT', 'TT'].includes(record?.name.report_code) && (
              <div className="d-flex w-25">
                <span className="d-flex align-items-center gap-2 me-2">
                  {record?.name.name}&nbsp;({record?.name.parenthisys_control}) - ({record?.name?.financial_statement})
                  {
                    record.name?.is_modified_third_party == 1 && (
                      <i
                        className="fa fa-exclamation-circle icn-alert text-warning"
                        aria-hidden="true"
                        title='Imported from third party'
                      ></i>
                    )
                  }
                </span>
              </div>
            ) || (
              <div className="d-flex w-25" onClick={() => handleClick(record)}>
                <span className="d-flex align-items-center gap-2 me-2">
                  <i
                    className={
                      showSubRecords
                        ? `fa fa-minus-circle icn-yellow`
                        : `fa fa-plus-circle icn-black`
                    }
                    aria-hidden="true"
                  ></i>
                  <div className='d-flex align-items-center gap-2'>
                    <span>{record?.name.name} &nbsp;
                      {
                        record.name?.is_modified_third_party == 1 && (
                          <i
                            className="fa fa-exclamation-circle icn-alert text-warning"
                            aria-hidden="true"
                            title='Imported from third party'
                          ></i>
                        )
                      }
                    </span>
                  </div>
                </span>
              </div>
            )
          }


          <div className="d-flex w-25 justify-content-center">
            <span className="d-flex align-items-center gap-2 me-2">
              {record?.name.account}
            </span>
          </div>
          <div className="d-flex justify-content-start w-25">
            <span className="d-flex align-items-center gap-2 me-2">
              {record?.name.description}
            </span>
          </div>
          <div className="d-flex w-25 justify-content-end">
            <a
              href="javascript:void(0)"
              onClick={() => _handleUpdate(record?.name.id)}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        {showSubRecords &&
          record['children'].map((subRecord: any, index: number) => (
            <div className="children">
              <CaRecod
                record={subRecord}
                handleEditRecord={_handleUpdate}
                isExpandAll={isExpandAll}
              />
            </div>
          ))}
        {/* {
                    showSubRecords && subRecords.map((subRecord: any, index: number) => (
                        <div className="children">
                            <CaRecod record={subRecord} handleEditRecord={_handleUpdate} refAttr={expandCollapseRef} />
                        </div>
                    ))
                } */}
      </div>
    </>
  )
}

export default CaRecod
