import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from 'react-bootstrap/Alert';
import { URLS } from '../../resources/constants'
import { getUser } from '../../store/user/selectors'
import { getBankAccountMatchSettings, getOpeningBalanceExistsSettings, getAccountSettings } from '../../store/settings/selectors'
// import { getFirstLoginStatus } from '../../store/settings/selectors'
import { useSettingsApi } from '../../resources/hooks/api/useSettingsApiHook';
import ChartAccountModal from '../../components/Modal/ChartOfAccount/ChartAccountModal'
//import { useUserDataApi } from '../../resources/hooks/api/userDataApiHook'
import { Link } from 'react-router-dom'

import RevrexSyncGLAcc from '../../components/Modal/RevrexSyncGLAcc/RevrexSyncGLAcc';
import revenueReconciliationIcon from '../../assets/images/revenue-reconciliation-icon-w.svg';
import expenseClassificationIcon from '../../assets/images/expense-classification-icon-w.svg';
import exitStrategyIcon from '../../assets/images/exit-strategy-icon-w.svg';
import reportIcon from '../../assets/images/report-icon-w.svg';

import { getBranch } from '../../store/user/selectors'
import SAlert from 'components/SAlert';
import toast from 'react-hot-toast';


interface Props {

}

const Dashboard: React.FC<Props> = () => {
    //   const _records = useSelector(getTableDataRecords)
    //   const _users = useSelector(getUser)
    const _getBankAccountMatchSettings = useSelector(getBankAccountMatchSettings);
    const _getOpeningBalanceExistsSettings = useSelector(getOpeningBalanceExistsSettings);
    const _getAccountSettings = useSelector(getAccountSettings);
    const userData = useSelector(getUser)
    
    // const { userAllowance, userStepStatus } = useUserApi();
    const { fetchAccountSettingsData, upgradeToLatestGL } = useSettingsApi();
    // const { dashboardRaioData } = useReportApi();
    // const _userSteps = useSelector(getFirstLoginStatus);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showCOAModal, setShowCOAModal] = useState<boolean>(false)
    const [createNewAccount, setCreateNewAccount] = useState<boolean>(false);
    const [confirmationToLatest, setConfirmationToLatest] = useState<boolean>(false);
    // const [dashboardRatio, setDashboardRatio] = useState<any | null>(null);
    const [modalSyncGl, setModalSyncGl] = useState<any>({
        syncBankAcc: false,
        syncSalesAcc: false
    });

    const branchData = useSelector(getBranch)

    useEffect(() => {
        // fetchAccMapping();
        getSettingsData();
        // fetchUserSteps();
        // getDashboardRatioData();
    }, [])

    /*const fetchAccMapping = () => {
        userAllowance({ branch_id: +branchData['id'] }, (message: string, resp: any) => {
        }, (message: string, resp: any) => {

        })
    }

    const fetchUserSteps = () => {
        setIsLoading(true);
        userStepStatus({ branch_id: +branchData['id'] }, (message: string, resp: any) => {
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);

        })
    }*/

    const getSettingsData = () => {
        fetchAccountSettingsData({ branch_id: +branchData['id'] }, (msg: string, resp: any) => {

        }, (msg: string, resp: any) => {

        })
    }

    const upsertCOA = (addNew: boolean) => {
        setCreateNewAccount(addNew);
        setShowCOAModal(true);
    }

    const hideCOAModal = () => {
        setShowCOAModal(false);
    }

    // const getDashboardRatioData = () => {
    //     setIsLoading(true);
    //     dashboardRaioData({ year: new Date().getFullYear(), branch_id: +branchData['id'] }, (msg: string, resp: any) => {
    //         setIsLoading(false)
    //         setDashboardRatio(resp.data.data);
    //     }, (msg: string, resp: any) => {
    //         setIsLoading(false)
    //     })
    // }

    const updateSyncGlModal = (params: any) => {
        setModalSyncGl((prevState: any) => {
            return {
                ...prevState,
                ...params
            }
        })
    }

    const confirmUpdationToLatest = () => {
        setIsLoading(true);
        upgradeToLatestGL({ branch_id: +branchData['id'] }, (msg: string, resp: any) => {
            setIsLoading(false);
            toast.success(resp?.data?.message || msg);
        }, (msg: string, resp: any) => {
            setIsLoading(false);
            toast.error(resp?.data?.message || msg);
        })
    }

    const closeSyncModal = () => {
        setModalSyncGl({
            syncBankAcc: false,
            syncSalesAcc: false
        })
        getSettingsData();
        // fetchAccMapping();
    }

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title py-4">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <h1 className="h3 font-weight-700  mb-0">Dashboard Overview</h1>
                        </div>
                    </div>
                </div>

                <div className="filter-btn">
                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                        <div className="loader"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {
                            !_getBankAccountMatchSettings && (
                                <Alert variant="danger">
                                    <span>
                                        Any of the bank account(s) from your RevRex account is not associated with related GL account. Please go back to your account and assign the associated GL account <strong>before going to Revenue/Expense GL Classification</strong>.
                                    </span>&nbsp;&nbsp;
                                    <button className='btn btn-sm btn-primary' onClick={() => updateSyncGlModal({ syncBankAcc: true })}>Sync With RevRex</button>
                                </Alert>
                            ) || <></>
                        }
                        {
                            _getOpeningBalanceExistsSettings && (
                                <Alert variant="danger">
                                    <span>
                                        Based on your recent data entry, it appears that you need to upgrade to the latest version of REVREX GL to generate accurate balance sheets and P&L statements. Please click the <strong>Upgrade to Latest</strong> button before proceeding with other tasks.
                                    </span>&nbsp;&nbsp;
                                    <button className='btn btn-sm btn-primary' onClick={() => setConfirmationToLatest(true)}>Upgrade to Latest</button>
                                </Alert>
                            ) || <></>
                        }
                        {/* {
                            !_getMappedAcc.sales_activity_match && (
                                <Alert variant="danger">
                                    <span>
                                        Any of the POS(s) from your RevRex account is not associated with related GL account. Please go back to your account and assign the associated GL account <strong>before going to Sales GL Classification</strong>.
                                    </span>&nbsp;&nbsp;
                                    <button className='btn btn-sm btn-primary' onClick={() => updateSyncGlModal({ syncSalesAcc: true })}>Sync With RevRex</button>
                                </Alert>
                            ) || <></>
                        } */}
                        {/* {
                            !_getMappedAcc.sales_activity_match && (
                                <Alert variant="danger">
                                    <span>
                                        Any of the POS(s) from your RevRex account is not associated with related GL account. Please go back to your account and assign the associated GL account <strong>before going to Sales GL Classification</strong>.
                                    </span>&nbsp;&nbsp;
                                    <button className='btn btn-sm btn-primary' onClick={() => updateSyncGlModal({ syncSalesAcc: true })}>Sync With RevRex</button>
                                </Alert>
                            ) || <></>
                        } */}
                    </div>
                </div>
                {
                    (_getAccountSettings.is_beginning_balance_exists == 1 && _getAccountSettings.is_coa_modified == 1) && _getAccountSettings.transaction_activity_status >= 3 ? (
                        <div className="dashboard-wrap">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="dashboard-box">
                                        <div className="accordion" id="accordionExample1">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <img src={revenueReconciliationIcon} alt="" /> Classification
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li>
                                                                <Link to={URLS.REVENUE_GL_CLASSIFICATION_STATUS}>Deposit Classification</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.EXPENSE_GL_CLASSIFICATION_STATUS}>Expense Classification</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.SALES_GL_CLASSIFICATION_STATUS}>Revenue Classification</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.CREDIT_CARD_GL_CLASSIFICATION_STATUS}>Credit Card Classification</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.BANK_RECONCILE}>Bank Reconciliation</Link>
                                                            </li>


                                                            {/* <li>
                                                        <Link to="#">Credit Card Transaction</Link>
                                                    </li> */}
                                                            {/* <li>
                                                        <Link to={URLS.BANK_TRANSACTION}>Bank Transactions</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sales Transactions</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Bank Reconciliation</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Sales Reconciliation</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Credit Card Transaction</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Credit Card Reconciliation </Link>
                                                    </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="dashboard-box">
                                        <div className="accordion" id="accordionExample2">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                        <img src={expenseClassificationIcon} alt="" /> Maintenance
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample2">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li>
                                                                <Link to={URLS.CHARTACCOUNT}>Manage Chart of Accounts</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'#'} onClick={() => upsertCOA(false)}>Change Existing Account</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={'#'} onClick={() => upsertCOA(true)}>Add New Account</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.VIEW_REGISTER}>Transaction Register</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.BUDGET}>Budget Entry</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.OPENNING_BALANCE}>Beginning Balance Entry</Link>
                                                            </li>
                                                            {/* {
                                                                _getMappedAcc?.sales_activity_count == 0 && (

                                                                    <li>
                                                                        <Link to={URLS.VIEW_REGISTER}>Transaction Register</Link>
                                                                    </li>

                                                                ) || <></>
                                                            }
                                                            <li>
                                                            {
                                                                _getMappedAcc?.sales_activity_count != 0 && (
                                                                    <Link to={URLS.VIEW_REGISTER}>Transaction Register</Link>

                                                                ) || <></>
                                                            }
                                                            </li> */}
                                                            {/* <li>
                                                        <Link to={URLS.SALES_GL_CLASSIFICATION}>Sales GL Classification</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={URLS.CREDIT_CARD_GL_CLASSIFICATION}>Credit GL Classification</Link>
                                                    </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="dashboard-box">
                                        <div className="accordion" id="accordionExample3">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                        <img src={exitStrategyIcon} alt="" /> Forms
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample3">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            {/* <li>
                                                        <Link to="#">Data Export</Link>
                                                    </li> */}
                                                            <li>
                                                                <Link to={URLS.FOURTHREETHREEB}>433-B</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.PERSONAL_INCOME_STATMENT}>Personal Income Statement</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.PERSONAL_BALANCE_SHEET}>Personal Balance Sheet</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#">Five Year Forecasting Entry</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="dashboard-box">
                                        <div className="accordion" id="accordionExample4">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFour">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                        <img src={reportIcon} alt="" /> Reports
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li>
                                                                <Link to={URLS.BANK_TRANSACTION}>Bank Transaction History</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.SALES_TRANSACTION}>Sales Transaction History</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.BALANCESHEET}>Balance Sheet</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={URLS.PROFITLOSS}>Profit and Loss Statement</Link>
                                                            </li>
                                                            {/* <li>
                                                                <Link to={URLS.STATEMENT_OF_CASH_FLOW}>Statement of Cash Flow</Link>
                                                            </li> */}
                                                            <li>
                                                                <Link to={URLS.BUSINESS_RATIO}>Business Ratios</Link>
                                                            </li>
                                                            {/* <li>
                                                                <Link to={URLS.FOURTHREETHREEB}>433-B FORM</Link>
                                                            </li> */}
                                                            {/* <li>
                                                        <Link to="#">Custom Reports</Link>
                                                    </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (_getAccountSettings.is_beginning_balance_exists == 1 && _getAccountSettings.is_coa_modified == 1) ? (
                        <div className="dashboard-wrap">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="steps-panel-wrapper">
                                        <ul className="steps-panel">
                                            <li className={`step-block ${_getAccountSettings.transaction_activity_status === 0 ? 'active' : 'completed'}`}>
                                                <div className='step-block-inner'>
                                                    <span><Link to={URLS.REVENUE_GL_CLASSIFICATION_STATUS} className='steps-panel-a'>3</Link></span>
                                                    <h4><Link to={URLS.REVENUE_GL_CLASSIFICATION_STATUS} className='steps-panel-a'>Deposit Classification</Link></h4>
                                                </div>

                                                {/* <Link to={URLS.REVENUE_GL_CLASSIFICATION_STATUS} className='steps-panel-a'>
                                                    <span>3</span>
                                                    <h4>Deposit Classification</h4>
                                                </Link> */}
                                            </li>
                                            <li className={`step-block ${_getAccountSettings.transaction_activity_status === 0 ? '' : _getAccountSettings.transaction_activity_status === 1 ? 'active' : _getAccountSettings.transaction_activity_status === 2 ? 'completed' : 'completed'}`}>
                                                <div className='step-block-inner'>
                                                    <span><Link to={_getAccountSettings.transaction_activity_status == 1 || _getAccountSettings.transaction_activity_status == 2 ? URLS.EXPENSE_GL_CLASSIFICATION_STATUS : 'javascript:void(0)'} className='steps-panel-a'>4</Link></span>
                                                    <h4><Link to={_getAccountSettings.transaction_activity_status == 1 || _getAccountSettings.transaction_activity_status == 2 ? URLS.EXPENSE_GL_CLASSIFICATION_STATUS : 'javascript:void(0)'} className='steps-panel-a'>Expense Classification</Link></h4>
                                                </div>

                                                {/* <Link to={_getAccountSettings.transaction_activity_status == 1 || _getAccountSettings.transaction_activity_status == 2 ? URLS.EXPENSE_GL_CLASSIFICATION_STATUS : 'javascript:void(0)'} className='steps-panel-a'>
                                                    <span>4</span>
                                                    <h4>Expense Classification</h4>
                                                </Link> */}
                                            </li>
                                            <li className={`step-block ${_getAccountSettings.transaction_activity_status === 0 ? '' : _getAccountSettings.transaction_activity_status === 1 ? '' : _getAccountSettings.transaction_activity_status === 2 ? 'active' : _getAccountSettings.transaction_activity_status >= 3 ? 'completed' : 'completed'}`}>
                                                <div className='step-block-inner'>
                                                    <span><Link to={_getAccountSettings.transaction_activity_status == 2 ? URLS.SALES_GL_CLASSIFICATION_STATUS : 'javascript:void(0)'} className='steps-panel-a'>5</Link></span>
                                                    <h4><Link to={_getAccountSettings.transaction_activity_status == 2 ? URLS.SALES_GL_CLASSIFICATION_STATUS : 'javascript:void(0)'} className='steps-panel-a'>Revenue Classification</Link></h4>
                                                </div>

                                                {/* <Link to={_getAccountSettings.transaction_activity_status == 2 ? URLS.SALES_GL_CLASSIFICATION_STATUS : 'javascript:void(0)'} className='steps-panel-a'>
                                                    <span>5</span>
                                                    <h4>Revenue Classification</h4>
                                                </Link> */}

                                            </li>

                                            {/* <li className="step-block completed">
                                            <div>
                                                <span>2</span>
                                                <h4>Expense GL Classification</h4>
                                            </div>
                                        </li> */}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="dashboard-wrap">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="steps-panel-wrapper">
                                        <ul className="steps-panel">
                                            <li className={`step-block ${!!_getAccountSettings.is_coa_modified ? 'completed' : 'active'}`}>
                                                <div className='step-block-inner'>
                                                    <span><Link to={URLS.CHARTACCOUNT} className='steps-panel-a'>1</Link></span>
                                                    <h4><Link to={URLS.CHARTACCOUNT} className='steps-panel-a'>Enter COA</Link></h4>
                                                </div>
                                            </li>
                                            <li className={`step-block ${!!_getAccountSettings.is_beginning_balance_exists ? 'completed' : 'active'}`}>
                                                <div className='step-block-inner'>
                                                    <span><Link to={_getAccountSettings.is_coa_modified == 1 ? URLS.OPENNING_BALANCE : 'javascript:void(0)'} className='steps-panel-a'>2</Link></span>
                                                    <h4><Link to={_getAccountSettings.is_coa_modified == 1 ? URLS.OPENNING_BALANCE : 'javascript:void(0)'} className='steps-panel-a'>Enter Begining Balance</Link></h4>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="dashboard-wrap mt-3">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="dashboard-box">
                                <div className="accordion" id="thirdPartyIntegration">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="thirdPartyIntegrationOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                                <img src={'third-party-coonect.svg'} alt="" /> Integration
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="collapse show" aria-labelledby="thirdPartyIntegrationOne" data-bs-parent="#thirdPartyIntegration">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>
                                                        {
                                                            (_getAccountSettings.is_beginning_balance_exists == 1 && _getAccountSettings.is_coa_modified == 1) ? (
                                                                <Link to={'javascript:void(0)'}>As you have setup your account already, can not login to any third party system.</Link>
                                                            ) : (
                                                                <Link to={URLS.THIRD_PARTY}>Third Party Accounting</Link>
                                                            )
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <div className="col-sm-12">
                            <div className="dashboard-box">
                                <div className="accordion" id="accordionExample1">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <img src={'third-party-coonect.svg'} alt="" /> Integration
                                            </button>
                                        </h2>
                                        <div className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                            <Link to={'javascript:void(0)'}>Third Party Accounting</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            {
                (modalSyncGl['syncBankAcc'] || modalSyncGl['syncSalesAcc']) &&
                <RevrexSyncGLAcc syncBankAcc={modalSyncGl['syncBankAcc']} syncSalesAcc={modalSyncGl['syncSalesAcc']} _handleCloseModal={closeSyncModal} /> || <></>
            }

            {
                showCOAModal && <ChartAccountModal closeModal={hideCOAModal} addRecord={createNewAccount} record={null} /> || <></>
            }

            <SAlert
                msg={`Based on your recent data entry, it appears that you need to upgrade to the latest version of REVREX GL to generate accurate balance sheets and P&L statements. Please click the 'Upgrade to Latest' button before proceeding with other tasks.`}
                show={confirmationToLatest}
                title={'Upgrade to get the latest version of GL system.'}
                confirmBtnText={'Upgrade to Latest'}
                cancelBtnText={'No, cancel'}
                onConfirm={confirmUpdationToLatest}
                showCancel={true}
                onCancel={() => setConfirmationToLatest(false)}
            />

        </div>
    )
}

export default Dashboard;