import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useParams, useNavigate, Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import toast from 'react-hot-toast';

import "react-datepicker/dist/react-datepicker.css";
import { RevenueGlClassificationFormI, RevenueGlClassificationSingleFormI } from '../../../resources/form-props';
import { BankSearchFormI } from '../../../resources/form-props';
import AsyncSelect from 'react-select/async';
import { glClassificationApi } from '../../../resources/hooks/api/glClassificationApi';
import { GLSearchFormValidate, BankRevenueValidate } from '../../../resources/form-validator';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { getBranch } from '../../../store/user/selectors'

import '../GlClassification.css'
import { GL_VP, SELECT_CUSTOM_STYLE, URLS } from '../../../resources/constants';
import { lastYears, calculateMaxHeightContent, usdCurrencyFormat } from '../../../resources/functions';
import SelectCustomOption from '../../../components/SelectCustomOption';
import { SelectInstance } from 'react-select'; // Import the correct type
import EquitySplitModal from 'components/Modal/DepositeClassification/EquitySplitModal';
import BankTransSplitModal from 'components/Modal/DepositeClassification/BankTransSplitModal';
import OtherSplitModal from 'components/Modal/DepositeClassification/OtherSplitModal';
import { Button } from 'react-bootstrap';

interface Props {

}

const RevenueGlClassification: React.FC<Props> = () => {

    const { glTransactionlist, revenueGlUpdate } = glClassificationApi();
    const { glAccountsList } = useChartAccountApi();
    const urlParams = useParams();
    const navigate = useNavigate();
    const branchData = useSelector(getBranch)

    const [records, setRecords] = useState<any>([]);
    //const [glAccount, setGlAccount] = useState<any[]>([]);
    const [glDebitAccount, setGlDebitAccount] = useState<any[]>([]);
    const [glCreditAccount, setGlCreditAccount] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0)
    const [selectedCalendar, setSelectedCalendar] = useState<any>({
        year: 2024,
        month: 1
    });
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#d1ffbd'));
    const [needFormAttention, setNeedFormAttention] = useState<boolean>(true);
    const checkAllRef = useRef<HTMLInputElement>(null);

    const { register, control, trigger, handleSubmit, reset, setFocus, setValue, getValues, formState: { errors } } = useForm<RevenueGlClassificationFormI>({
        defaultValues: {
            revenues: []
        },
        resolver: yupResolver(BankRevenueValidate)
    });
    const { fields, append: formAppend, remove: formRemove } = useFieldArray({
        control,
        name: "revenues"
    });

    const { control: control2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm<BankSearchFormI>({
        defaultValues: {
            month: {
                value: '01',
                label: 'January'
            },
            year: { value: '2024', label: '2024' },
        },
        resolver: yupResolver(GLSearchFormValidate)
    });

    // useEffect(() => {
    //     fechAccountList().then(r => {
    //         setGlAccount(r);
    //     })
    // }, [])
    useEffect(() => {
        fetchTransactionList();
        fechBankAccountList().then(r => {
            setGlDebitAccount(r);
        })
        fechAllAccountList().then(r => {
            setGlCreditAccount(r);
        })
    }, [])

    useEffect(() => {
        records.map((record: any, index: number) => {
            const depositData = record['breakdowns'].filter((e: any) => e['account_type'] == "Deposit");
            const equityData = record['breakdowns'].filter((e: any) => e['account_type'] == "Equity");
            const bankTransferData = record['breakdowns'].filter((e: any) => e['account_type'] == "Bank_Transfer");
            const otherData = record['breakdowns'].filter((e: any) => e['account_type'] == "Others");
            const adjustedBankDepositData = record['breakdowns'].filter((e: any) => e['account_type'] == "Adjusted_Bank_Deposit");

            formAppend({
                formIndex: index,
                checked: false,
                record_id: record['id'],
                parent_id: record['parent_id'],
                description: record['description'],
                account_name: record['account_name'],
                transaction_at: record['transaction_at'],
                deposit: depositData.length && depositData[0]['account_value'] || 0,
                equity: equityData.length && equityData[0]['account_value'] || 0,
                bank_transfer: bankTransferData.length && bankTransferData[0]['account_value'] || 0,
                others: otherData.length && otherData[0]['account_value'] || 0,
                adjusted_bank_deposit: adjustedBankDepositData.length && adjustedBankDepositData[0]['account_value'] || 0,
                equity_master_account_id: equityData.length && equityData[0]['account_chart'] && {
                    label: equityData[0]['account_chart']['name'] + '(' + equityData[0]['account_chart']['account'] + ')',
                    value: equityData[0]['account_chart']['id']
                } || null,
                bank_transfer_master_account_id: bankTransferData.length && bankTransferData[0]['account_chart'] && {
                    label: bankTransferData[0]['account_chart']['name'] + '(' + bankTransferData[0]['account_chart']['account'] + ')',
                    value: bankTransferData[0]['account_chart']['id']
                } || null,
                others_master_account_id: otherData.length && otherData[0]['account_chart'] && {
                    label: otherData[0]['account_chart']['name'] + '(' + otherData[0]['account_chart']['account'] + ')',
                    value: otherData[0]['account_chart']['id']
                } || null,
                deposit_master_account_id: depositData.length && depositData[0]['account_chart'] && {
                    label: depositData[0]['account_chart']['name'] + '(' + depositData[0]['account_chart']['account'] + ')',
                    value: depositData[0]['account_chart']['id']
                } || null,
                equity_split: [],
                bank_transfer_split: [],
                others_split: [],
                // adjusted_bank_deposity_master_account_id: null
            })
        });
        const sumAmount = records.reduce((a: number, record: any) => {
            const depositData = record['breakdowns'].filter((e: any) => e['account_type'] == "Deposit");
            const equityData = record['breakdowns'].filter((e: any) => e['account_type'] == "Equity");
            const bankTransferData = record['breakdowns'].filter((e: any) => e['account_type'] == "Bank_Transfer");
            const otherData = record['breakdowns'].filter((e: any) => e['account_type'] == "Others");

            // depositData[0]['account_value'] && depositData[0]['account_chart']
            a += (depositData.length && (depositData[0]['account_value'] == 0 ? 0 : depositData[0]['account_chart'] ? 0 : 1)) + (equityData.length && equityData[0]['account_value'] || 0) + (bankTransferData.length && bankTransferData[0]['account_value'] || 0) + (otherData.length && otherData[0]['account_value'] || 0);

            return a;
        }, 0)

        if (sumAmount) {
            setNeedFormAttention(true)
        } else {
            setNeedFormAttention(false)
        }

    }, [records])

    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(131));
    })

    useEffect(() => {
        if (fields.length == 0) {
            if (checkAllRef.current) {
                checkAllRef.current.checked = false;
            }
        }
    }, [fields])

    const fetchTransactionList = () => {
        const params: any = {
            "page": 1,
            "per_page": 500,
            year: urlParams['year'],
            month: urlParams['month'],
            branch_id: +branchData['id']
        }

        params['id'] = 0;
        params['account_type'] = 3;

        setIsLoading(true);
        glTransactionlist(params, (message: string, resp: any) => {
            reset();
            setRecords(resp.data.data.result);
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }
    const fechBankAccountList = (searchText: string = ''): Promise<any[]> => {

        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            // params['parent_account_in'] = GL_VP.BANK_ACCOUNT.account.join(',');
            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                return resolve(data);
                //console.log(data);
                // setGlAccount(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }

    const fechAllAccountList = (searchText: string = ''): Promise<any[]> => {
        return new Promise<any>((resolve1, reject1) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            // params['parent_name_in'] = 'BANK ACCOUNTS';
            // params['parent_account_not_in'] = GL_VP.BANK_ACCOUNT.account.join(',');
            
            glAccountsList(params, (message: string, resp: any) => {

                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));

                return resolve1(data);
                //console.log(resp);
                // setGlAccount(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve1([]);
            })
        })
    }

    const filterBankAccountList = async (inputValue: string) => {
        const data = await fechBankAccountList(inputValue)
        return data;
    };
    const filterAllAccountList = async (inputValue: string) => {
        const data = await fechAllAccountList(inputValue)
        return data;
    };


    const handleCheckAllChange = (e: any) => {
        if (checkAllRef.current) {
            checkAllRef.current.checked = e.target.checked;

            fields.map((record: any, index: number) => {
                setValue(`revenues.${index}.checked`, e.target.checked)
            })
            /*records.map((record: any, index: number) => {
                setValue(`revenues.${index}.checked`, e.target.checked)
            })*/
        }
        // setChecked(
        //     e.target.checked ? records.map((c: any) => c.id) : []
        // );
    };

    const handleCheckAll = (e: any, index: number) => {
        setValue(`revenues.${index}.checked`, e.target.checked);
        if (e.target.checked) {
            const checkedRecords = fields.filter((record: any, index: number) => getValues(`revenues.${index}.checked`) === true);

            if (checkedRecords.length == fields.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = true;
                }
            }
        } else {
            const uncheckedRecords = fields.filter((record: any, index: number) => getValues(`revenues.${index}.checked`) === false);

            if (uncheckedRecords.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = false;
                }
            }
        }
    }


    // For Equity Transaction
    const [showEquitySplitModal, setShowEquitySplitModal] = useState(false);
    const [selectedEquityRecord, setSelectedEquityRecord] = useState<any>(null);
    const handleEquitySplitClick = (data: any, id: number) => {
        setSelectedEquityRecord(data);
        setShowEquitySplitModal(true);
    };
    const [splitEquityData, setSplitEquityData] = React.useState<any[]>([]);
    const handleSplitEquitySave = (rowID: number, indexNo: number, splits: any[]) => {
        const newSplits = splits.map(split => ({ ...split }));
        setSplitEquityData(prevData => {
            const updatedSplits = prevData.filter(split => split.rowID !== rowID);
            return [...updatedSplits, ...newSplits];
        });
        const finalSplits = newSplits.map(split => {
            const { rowID, ...rest } = split;
            return rest;
        });
        //console.log(finalSplits);
        setValue(`revenues.${indexNo}.equity_split`, finalSplits)

    };

    // For Bank Transaction
    const [showBankTransSplitModal, setShowBankTransSplitModal] = useState(false);
    const [selectedBankTransRecord, setSelectedBankTransRecord] = useState<any>(null);
    const handleBankTransSplitClick = (data: any, id: number) => {
        setSelectedBankTransRecord(data);
        setShowBankTransSplitModal(true);
    };
    const [splitBankTransData, setSplitBankTransData] = React.useState<any[]>([]);
    const handleSplitBankTransSave = (rowID: number, indexNo: number, splits: any[]) => {
        const newSplits = splits.map(split => ({ ...split }));
        setSplitBankTransData(prevData => {
            const updatedSplits = prevData.filter(split => split.rowID !== rowID);
            return [...updatedSplits, ...newSplits];
        });
        const finalSplits = newSplits.map(split => {
            const { rowID, ...rest } = split;
            return rest;
        });
        //console.log(finalSplits);
        setValue(`revenues.${indexNo}.bank_transfer_split`, finalSplits)

    };

    // For Other Transaction
    const [showOtherSplitModal, setShowOtherSplitModal] = useState(false);
    const [selectedOtherRecord, setSelectedOtherRecord] = useState<any>(null);
    const handleOtherSplitClick = (data: any, id: number) => {
        setSelectedOtherRecord(data);
        setShowOtherSplitModal(true);
    };
    const [splitOtherData, setSplitOtherData] = React.useState<any[]>([]);
    const handleSplitOtherSave = (rowID: number, indexNo: number, splits: any[]) => {
        const newSplits = splits.map(split => ({ ...split }));
        setSplitOtherData(prevData => {
            const updatedSplits = prevData.filter(split => split.rowID !== rowID);
            return [...updatedSplits, ...newSplits];
        });
        const finalSplits = newSplits.map(split => {
            const { rowID, ...rest } = split;
            return rest;
        });
        //console.log(finalSplits);
        setValue(`revenues.${indexNo}.others_split`, finalSplits)

    };

    const processData = (data: any) => {
        
        const toRemoveItems: number[] = [];
        const records = data['revenues']
            // .filter((e: any) => e['checked'])
            .map((d: any, index: number) => {
                if (d['checked']) {
                    toRemoveItems.push(index)
                    let isFirstRow = true; // Track if it's the first row for setting id

                    // Process the existing split data within the record
                    let equity_split = d['equity_split']
                        .map((split: any) => {
                            if (isFirstRow) {
                                isFirstRow = false;
                                return {
                                    ...split,
                                    id: split.id, // Only first item retains id
                                    master_chart_of_account_id: split.master_chart_of_account_id ? split.master_chart_of_account_id.value : null,
                                };
                            } else {
                                return {
                                    ...split,
                                    id: null, // Set id to null for subsequent entries
                                    master_chart_of_account_id: split.master_chart_of_account_id ? split.master_chart_of_account_id.value : null,
                                };
                            }
                        })
                        .filter((item: any) => item.master_chart_of_account_id !== null); // Filter out splits with null gross_sale_master_account_id

                    // If no split data found, add a default split entry
                    if (equity_split.length === 0) {
                        equity_split = [
                            {
                                id: d.record_id,
                                amount: d.equity,
                                master_chart_of_account_id: d['equity_master_account_id'] && d['equity_master_account_id']['value'] && +d['equity_master_account_id']['value'] || null,
                            },
                        ];
                    }
                    let isFirstRow1 = true;
                    let bank_transfer_split = d['bank_transfer_split']
                        .map((split: any) => {
                            if (isFirstRow1) {
                                isFirstRow1 = false;
                                return {
                                    ...split,
                                    id: split.id, // Only first item retains id
                                    master_chart_of_account_id: split.master_chart_of_account_id ? split.master_chart_of_account_id.value : null,
                                };
                            } else {
                                return {
                                    ...split,
                                    id: null, // Set id to null for subsequent entries
                                    master_chart_of_account_id: split.master_chart_of_account_id ? split.master_chart_of_account_id.value : null,
                                };
                            }
                        })
                        .filter((item: any) => item.master_chart_of_account_id !== null); // Filter out splits with null gross_sale_master_account_id

                    // If no split data found, add a default split entry
                    if (bank_transfer_split.length === 0) {
                        bank_transfer_split = [
                            {
                                id: d.record_id,
                                amount: d.bank_transfer,
                                master_chart_of_account_id: d['bank_transfer_master_account_id'] && d['bank_transfer_master_account_id']['value'] && +d['bank_transfer_master_account_id']['value'] || null,
                            },
                        ];
                    }
                    let isFirstRow2 = true;
                    let others_split = d['others_split']
                        .map((split: any) => {
                            if (isFirstRow2) {
                                isFirstRow2 = false;
                                return {
                                    ...split,
                                    id: split.id, // Only first item retains id
                                    master_chart_of_account_id: split.master_chart_of_account_id ? split.master_chart_of_account_id.value : null,
                                };
                            } else {
                                return {
                                    ...split,
                                    id: null, // Set id to null for subsequent entries
                                    master_chart_of_account_id: split.master_chart_of_account_id ? split.master_chart_of_account_id.value : null,
                                };
                            }
                        })
                        .filter((item: any) => item.master_chart_of_account_id !== null); // Filter out splits with null gross_sale_master_account_id

                    // If no split data found, add a default split entry
                    if (others_split.length === 0) {
                        others_split = [
                            {
                                id: d.record_id,
                                amount: d.others,
                                master_chart_of_account_id: d['others_master_account_id'] && d['others_master_account_id']['value'] && +d['others_master_account_id']['value'] || null,
                            },
                        ];
                    }
                    return {
                        id: d.record_id,
                        parent_id: d.parent_id,
                        description: d.description,
                        account_name: d.account_name,
                        transaction_at: d.transaction_at,
                        deposit: d.deposit,
                        //equity: d.equity,
                        //bank_transfer: d.bank_transfer,
                        //others: d.others,
                        adjusted_bank_deposit: d.adjusted_bank_deposit,
                        deposit_master_account_id: d['deposit_master_account_id'] && d['deposit_master_account_id']['value'] && +d['deposit_master_account_id']['value'] || null,
                        //equity_master_account_id: d['equity_master_account_id'] && d['equity_master_account_id']['value'] && +d['equity_master_account_id']['value'] || null,
                        //bank_transfer_master_account_id: d['bank_transfer_master_account_id'] && d['bank_transfer_master_account_id']['value'] && +d['bank_transfer_master_account_id']['value'] || null,
                        //others_master_account_id: d['others_master_account_id'] && d['others_master_account_id']['value'] && +d['others_master_account_id']['value'] || null
                        equity_split : equity_split,
                        bank_transfer_split : bank_transfer_split,
                        others_split : others_split,
                    }
                } else {
                    return null;
                }
            }).filter((e: any) => e);
        //console.log(records);
        postData(records, toRemoveItems)
    }
    const postData = (records: any, toRemoveItems: number[]) => {
        if (records.length) {
            setIsLoading(true);
            revenueGlUpdate({ records, branch_id: +branchData['id'] }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.success(message)
                formRemove(toRemoveItems)
                if (records.length == fields.length) {
                    navigate(-1);
                }
                // fetchTransactionList();
            }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.error(message);
            })
        } else {
            toast.error("Please select at least one record");
        }
    }
    const processSearchData = (data: any) => {
        setSelectedCalendar({
            year: +data['year']['value'],
            month: +data['month']['value']
        })
    }
    const handleSinglePost = (index: number) => {
        const data = getValues();
        const toRemoveItems: number[] = [];
        if (data['revenues'][index]['checked']) {
            const records = [data['revenues'][index]]
                .map((d: any) => {
                    return {
                        id: d.record_id,
                        parent_id: d.parent_id,
                        description: d.description,
                        account_name: d.account_name,
                        transaction_at: d.transaction_at,
                        deposit: d.deposit,
                        equity: d.equity,
                        bank_transfer: d.bank_transfer,
                        others: d.others,
                        // adjusted_bank_deposit: d.adjusted_bank_deposit,
                        deposit_master_account_id: d['deposit_master_account_id'] && d['deposit_master_account_id']['value'] && +d['deposit_master_account_id']['value'] || null,
                        equity_master_account_id: d['equity_master_account_id'] && d['equity_master_account_id']['value'] && +d['equity_master_account_id']['value'] || null,
                        bank_transfer_master_account_id: d['bank_transfer_master_account_id'] && d['bank_transfer_master_account_id']['value'] && +d['bank_transfer_master_account_id']['value'] || null,
                        others_master_account_id: d['others_master_account_id'] && d['others_master_account_id']['value'] && +d['others_master_account_id']['value'] || null
                    }
                });
            toRemoveItems.push(index)
            postData(records, toRemoveItems)
        } else {
            toast.error("Please select the record");
        }
    }
    const handleFieldValidation = async (index: number) => {
        // Trigger validation for the specified field within the array
        const isValidated = await trigger(`revenues.${index}`);
        if (isValidated) {
            handleSinglePost(index)
        }
    };

    const selectRefs = useRef<SelectInstance<any, false, any>[]>([]);


    const handleSelectChange = (index: number, e: any) => {
        setTimeout(() => {
            let nextIndex = index + 1;
            while (nextIndex < selectRefs.current.length) {
                const nextSelect = selectRefs.current[nextIndex];
                if (nextSelect && !nextSelect.props.isDisabled) {
                    nextSelect.focus();
                    break;
                }
                nextIndex++;
            }
        }, 0);
    };
    const selectRefs2 = useRef<SelectInstance<any, false, any>[]>([]);
    const handleSelectChange2 = (index: number, e: any) => {
        setTimeout(() => {
            let nextIndex = index + 1;
            while (nextIndex < selectRefs2.current.length) {
                const nextSelect = selectRefs2.current[nextIndex];
                if (nextSelect && !nextSelect.props.isDisabled) {
                    nextSelect.focus();
                    break;
                }
                nextIndex++;
            }
        }, 0);
    };
    const selectRefs3 = useRef<SelectInstance<any, false, any>[]>([]);
    const handleSelectChange3 = (index: number, e: any) => {
        setTimeout(() => {
            let nextIndex = index + 1;
            while (nextIndex < selectRefs3.current.length) {
                const nextSelect = selectRefs3.current[nextIndex];
                if (nextSelect && !nextSelect.props.isDisabled) {
                    nextSelect.focus();
                    break;
                }
                nextIndex++;
            }
        }, 0);
    };

    const selectRefs4 = useRef<SelectInstance<any, false, any>[]>([]);
    const handleSelectChange4 = (index: number, e: any) => {
        setTimeout(() => {
            let nextIndex = index + 1;
            while (nextIndex < selectRefs4.current.length) {
                const nextSelect = selectRefs4.current[nextIndex];
                if (nextSelect && !nextSelect.props.isDisabled) {
                    nextSelect.focus();
                    break;
                }
                nextIndex++;
            }
        }, 0);
    };


    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Deposit Classification</h1>
                        </div>
                        <div className="col-sm-6 justify-content-end align-items-center d-flex">
                            <Link to={URLS.REVENUE_GL_CLASSIFICATION_STATUS} className="btn btn-link">Back To Classification Status</Link>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className="filter-btn">
                            <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                <div className="loader"></div>
                            </div>
                        </div>
                        <Form>
                            {
                                !needFormAttention && (
                                    <Alert variant={'info'}>
                                        <i className="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;
                                        No adjustment required. Please post.
                                    </Alert>
                                ) || <></>
                            }
                            {
                                fields.length > 0 && (
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={handleSubmit(processData)}>Post</button>
                                    </div>
                                ) || <></>
                            }

                            <br></br>
                            <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}>
                                <table className="table table-default">
                                    <thead>
                                        <tr className="align-middle">
                                            <th className="w-5 text-center">
                                                <div className="form-check ">
                                                    <label >
                                                        <input ref={checkAllRef} className="form-check-input wh-20 checkAll" type="checkbox" onChange={handleCheckAllChange} />
                                                    </label>
                                                </div>
                                            </th>
                                            <th className="text-center">Date</th>
                                            <th className="text-start">Description </th>
                                            <th className="text-end">Deposit </th>
                                            <th className="text-start">Debit GL Account </th>
                                            <th className="text-end">Equity </th>
                                            <th className="text-start">Credit GL Account </th>
                                            <th></th>
                                            <th className="text-end">Bank Transfer </th>
                                            <th className="text-start">Credit GL Account </th>
                                            <th></th>
                                            <th className="text-end">Others </th>
                                            <th className="text-start">Credit GL Account </th>
                                            <th></th>
                                            <th className="text-end">Adjusted Bank Deposit </th>
                                            {/* <th className="text-center">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fields.length > 0 ?
                                                fields.map((data: any, index: any) => {
                                                    const hasEquitySplitData = splitEquityData.some((split: any) => split.id === data.record_id);
                                                    const splitEquityCreditedAccounts = splitEquityData
                                                        .filter((split) => split.id === data.record_id)
                                                        .map((split) => split.master_chart_of_account_id.label)
                                                        .join(', ');

                                                    const hasBankTransSplitData = splitBankTransData.some((split: any) => split.id === data.record_id);
                                                    const splitBankTransCreditedAccounts = splitBankTransData
                                                        .filter((split) => split.id === data.record_id)
                                                        .map((split) => split.master_chart_of_account_id.label)
                                                        .join(', ');

                                                    const hasOtherSplitData = splitOtherData.some((split: any) => split.id === data.record_id);
                                                    const splitOtherCreditedAccounts = splitOtherData
                                                        .filter((split) => split.id === data.record_id)
                                                        .map((split) => split.master_chart_of_account_id.label)
                                                        .join(', ');

                                                    return (
                                                        <tr className="align-middle h-60" key={data['id']}>
                                                            <td className="text-center">
                                                                <div className="form-check ">
                                                                    <label >
                                                                        <input className="form-check-input wh-20 checkbox" {...register(`revenues.${index}.checked`)}
                                                                            name={`revenues.${index}.checked`}
                                                                            defaultChecked={fields[index]['checked']}
                                                                            type="checkbox" onChange={(e: any) => handleCheckAll(e, index)} />

                                                                        {/* <input className="form-check-input wh-20 checkbox" {...register(`revenues.${index}.checkId`)} name={`revenues.${index}.checkId`}
                                                                        checked={checked.includes(data.id)} onChange={(e) => handleCheckboxChange(e, data)} value={data.id} type="checkbox" /> */}
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td className="text-center"><strong>{moment(fields[index].transaction_at).format('MM-DD-YYYY h:mm:ss')}</strong></td>
                                                            <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={fields[index].description || 'NA'}>{fields[index].description || 'NA'}</td>
                                                            <td className="text-end">{usdCurrencyFormat(fields[index].deposit)}</td>
                                                            <td className="text-start">
                                                                <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                    <Controller
                                                                        control={control}
                                                                        name={`revenues.${index}.deposit_master_account_id`}
                                                                        render={({ field: { value, onChange, onBlur } }) => (
                                                                            <AsyncSelect
                                                                                isClearable={true}
                                                                                placeholder={'Please select GL acc'}
                                                                                defaultOptions={glDebitAccount}
                                                                                loadOptions={filterBankAccountList}
                                                                                // onChange={(e:any) => {
                                                                                //     onChange(e);
                                                                                //     // Manually blur the input field to remove focus
                                                                                //     const activeElement = document.activeElement as HTMLElement;
                                                                                //     activeElement?.blur();
                                                                                //   }}
                                                                                ref={(el) => (selectRefs.current[index] = el as SelectInstance<any, false, any>)}
                                                                                onChange={(e: any) => {
                                                                                    onChange(e);
                                                                                    handleSelectChange(index, e);
                                                                                }}
                                                                                className='vp-dropdown'
                                                                                menuPosition='fixed'
                                                                                value={value}
                                                                                defaultValue={value}
                                                                                styles={selectCustomStyle}
                                                                                components={{ Option: SelectCustomOption }}
                                                                                isDisabled={!!fields[index].deposit_master_account_id}
                                                                            />
                                                                        )}
                                                                    />
                                                                    {
                                                                        !!fields[index].deposit_master_account_id && <input {...register(`revenues.${index}.deposit_master_account_id`)} type="hidden" />
                                                                    }
                                                                </Form.Group>
                                                                {
                                                                    errors && errors.revenues && errors.revenues[index] && errors.revenues[index]?.deposit_master_account_id && errors.revenues[index]?.deposit_master_account_id?.message && (
                                                                        <Form.Text className="text-danger d-flex">
                                                                            <strong>
                                                                                {errors.revenues[index]?.deposit_master_account_id?.message as ReactNode || ''}
                                                                            </strong>
                                                                        </Form.Text>
                                                                    ) || ''
                                                                }
                                                            </td>
                                                            <td className="text-end">{usdCurrencyFormat(fields[index].equity)}</td>
                                                            {
                                                                hasEquitySplitData ? (

                                                                    <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={splitEquityCreditedAccounts || 'NA'}>
                                                                        {splitEquityCreditedAccounts}
                                                                    </td>
                                                                ) : (
                                                                    <td className="text-start">
                                                                        <Form.Group className="col-sm-12" controlId="formBasicEmail">
                                                                            <Controller
                                                                                disabled={!fields[index].equity}
                                                                                control={control}
                                                                                name={`revenues.${index}.equity_master_account_id`}
                                                                                render={({ field: { value, onChange, disabled } }) => (
                                                                                    <AsyncSelect
                                                                                        isClearable={true}
                                                                                        placeholder={'Please select GL acc'}
                                                                                        defaultOptions={glCreditAccount}
                                                                                        loadOptions={filterAllAccountList}
                                                                                        // onChange={(e:any) => {
                                                                                        //     onChange(e);
                                                                                        //     // Manually blur the input field to remove focus
                                                                                        //     const activeElement = document.activeElement as HTMLElement;
                                                                                        //     activeElement?.blur();
                                                                                        //   }}
                                                                                        ref={(el) => (selectRefs2.current[index] = el as SelectInstance<any, false, any>)}
                                                                                        onChange={(e: any) => {
                                                                                            onChange(e);
                                                                                            handleSelectChange2(index, e);
                                                                                        }}
                                                                                        className='vp-dropdown'
                                                                                        menuPosition='fixed'
                                                                                        isDisabled={disabled}
                                                                                        styles={selectCustomStyle}
                                                                                        value={value}
                                                                                        components={{ Option: SelectCustomOption }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Form.Group>
                                                                        {
                                                                            errors && errors.revenues && errors.revenues[index] && errors.revenues[index]?.equity_master_account_id && errors.revenues[index]?.equity_master_account_id?.message && (
                                                                                <Form.Text className="text-danger d-flex">
                                                                                    <strong>{errors.revenues[index]?.equity_master_account_id?.message as ReactNode || ''}</strong>
                                                                                </Form.Text>
                                                                            ) || ''
                                                                        }
                                                                    </td>
                                                                )
                                                            }
                                                            <td>
                                                                <Button variant="primary" disabled={fields[index].equity === 0} onClick={() => handleEquitySplitClick(data, fields[index].record_id)}>
                                                                    {hasEquitySplitData ? 'Modify Split' : 'Split Classification'}
                                                                </Button>
                                                            </td>
                                                            <td className="text-end">{usdCurrencyFormat(fields[index].bank_transfer)}</td>
                                                            {
                                                                hasBankTransSplitData ? (

                                                                    <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={splitBankTransCreditedAccounts || 'NA'}>
                                                                        {splitBankTransCreditedAccounts}
                                                                    </td>
                                                                ) : (
                                                                    <td className="text-start">
                                                                        <Form.Group className="col-sm-12" controlId="formBasicEmail">
                                                                            <Controller
                                                                                control={control}
                                                                                disabled={!fields[index].bank_transfer}
                                                                                name={`revenues.${index}.bank_transfer_master_account_id`}
                                                                                render={({ field: { value, onChange, disabled } }) => (
                                                                                    <AsyncSelect
                                                                                        isClearable={true}
                                                                                        placeholder={'Please select GL acc'}
                                                                                        defaultOptions={glCreditAccount} loadOptions={filterAllAccountList}
                                                                                        // onChange={(e:any) => {
                                                                                        //     onChange(e);
                                                                                        //     // Manually blur the input field to remove focus
                                                                                        //     const activeElement = document.activeElement as HTMLElement;
                                                                                        //     activeElement?.blur();
                                                                                        //   }}
                                                                                        ref={(el) => (selectRefs3.current[index] = el as SelectInstance<any, false, any>)}
                                                                                        onChange={(e: any) => {
                                                                                            onChange(e);
                                                                                            handleSelectChange3(index, e);
                                                                                        }}
                                                                                        className='vp-dropdown'
                                                                                        menuPosition='fixed'
                                                                                        isDisabled={disabled}
                                                                                        styles={selectCustomStyle}
                                                                                        value={value}
                                                                                        components={{ Option: SelectCustomOption }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Form.Group>
                                                                        {
                                                                            errors && errors.revenues && errors.revenues[index] && errors.revenues[index]?.bank_transfer_master_account_id && errors.revenues[index]?.bank_transfer_master_account_id?.message && (
                                                                                <Form.Text className="text-danger d-flex">
                                                                                    <strong>{errors.revenues[index]?.bank_transfer_master_account_id?.message as ReactNode || ''}</strong>
                                                                                </Form.Text>
                                                                            ) || ''
                                                                        }
                                                                    </td>
                                                                )
                                                            }
                                                            <td>
                                                                <Button variant="primary" disabled={fields[index].bank_transfer === 0} onClick={() => handleBankTransSplitClick(data, fields[index].record_id)}>
                                                                    {hasBankTransSplitData ? 'Modify Split' : 'Split Classification'}
                                                                </Button>
                                                            </td>
                                                            <td className="text-end">{usdCurrencyFormat(fields[index].others)}</td>
                                                            {
                                                                hasOtherSplitData ? (

                                                                    <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={splitOtherCreditedAccounts || 'NA'}>
                                                                        {splitOtherCreditedAccounts}
                                                                    </td>
                                                                ) : (
                                                                    <td className="text-start">
                                                                        <Form.Group className="col-sm-12" controlId="formBasicEmail">
                                                                            <Controller
                                                                                control={control}
                                                                                disabled={!fields[index].others}
                                                                                name={`revenues.${index}.others_master_account_id`}
                                                                                render={({ field: { value, onChange, disabled } }) => (
                                                                                    <AsyncSelect
                                                                                        isClearable={true}
                                                                                        placeholder={'Please select GL acc'}
                                                                                        defaultOptions={glCreditAccount}
                                                                                        loadOptions={filterAllAccountList}
                                                                                        // onChange={(e:any) => {
                                                                                        //     onChange(e);
                                                                                        //     // Manually blur the input field to remove focus
                                                                                        //     const activeElement = document.activeElement as HTMLElement;
                                                                                        //     activeElement?.blur();
                                                                                        //   }}
                                                                                        ref={(el) => (selectRefs4.current[index] = el as SelectInstance<any, false, any>)}
                                                                                        onChange={(e: any) => {
                                                                                            onChange(e);
                                                                                            handleSelectChange4(index, e);
                                                                                        }}
                                                                                        className='vp-dropdown'
                                                                                        menuPosition='fixed'
                                                                                        isDisabled={disabled}
                                                                                        styles={selectCustomStyle}
                                                                                        value={value}
                                                                                        components={{ Option: SelectCustomOption }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Form.Group>
                                                                        {
                                                                            errors && errors.revenues && errors.revenues[index] && errors.revenues[index]?.others_master_account_id && errors.revenues[index]?.others_master_account_id?.message && (
                                                                                <Form.Text className="text-danger d-flex">
                                                                                    <strong>{errors.revenues[index]?.others_master_account_id?.message as ReactNode || ''}</strong>
                                                                                </Form.Text>
                                                                            ) || ''
                                                                        }
                                                                    </td>
                                                                )
                                                            }
                                                            <td>
                                                                <Button variant="primary" disabled={fields[index].others === 0} onClick={() => handleOtherSplitClick(data, fields[index].record_id)}>
                                                                    {hasOtherSplitData ? 'Modify Split' : 'Split Classification'}
                                                                </Button>
                                                            </td>
                                                            <td className="text-end">{usdCurrencyFormat(fields[index].adjusted_bank_deposit)}</td>
                                                            {/* <td className="text-center">
                                                            <button type="button" className="btn btn-secondary" onClick={() => handleFieldValidation(index)}>Post</button>
                                                        </td> */}
                                                        </tr>
                                                    );
                                                }) : <tr>
                                                    <td colSpan={12} className='text-center'>No data found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                        {/* <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} recordPerPage={recordPerPage} lastIndex={lastIndex} firstIndex={firstIndex} npage={npage} /> */}
                    </div>

                </div>
            </div>

            <EquitySplitModal
                rowID={selectedEquityRecord?.record_id || 0}
                indexNo={selectedEquityRecord?.formIndex || 0}
                show={showEquitySplitModal}
                onHide={() => setShowEquitySplitModal(false)}
                onSave={handleSplitEquitySave}
                originalAmount={selectedEquityRecord?.equity || 0}
                glCreditAccount={glCreditAccount}
                transactionAt={selectedEquityRecord?.transaction_at || ''}
                description={selectedEquityRecord?.description || ''}
                splitData={splitEquityData}
                filterAllAccountList={filterAllAccountList}
            />

            <BankTransSplitModal
                rowID={selectedBankTransRecord?.record_id || 0}
                indexNo={selectedBankTransRecord?.formIndex || 0}
                show={showBankTransSplitModal}
                onHide={() => setShowBankTransSplitModal(false)}
                onSave={handleSplitBankTransSave}
                originalAmount={selectedBankTransRecord?.bank_transfer || 0}
                glCreditAccount={glCreditAccount}
                transactionAt={selectedBankTransRecord?.transaction_at || ''}
                description={selectedBankTransRecord?.description || ''}
                splitData={splitBankTransData}
                filterAllAccountList={filterAllAccountList}
            />

            <OtherSplitModal
                rowID={selectedOtherRecord?.record_id || 0}
                indexNo={selectedOtherRecord?.formIndex || 0}
                show={showOtherSplitModal}
                onHide={() => setShowOtherSplitModal(false)}
                onSave={handleSplitOtherSave}
                originalAmount={selectedOtherRecord?.others || 0}
                glCreditAccount={glCreditAccount}
                transactionAt={selectedOtherRecord?.transaction_at || ''}
                description={selectedOtherRecord?.description || ''}
                splitData={splitOtherData}
                filterAllAccountList={filterAllAccountList}
            />
        </div>


    )
}

export default RevenueGlClassification;