import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import { useNavigate, Link } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import toast from 'react-hot-toast';
import DatePicker from "react-datepicker";
import InputGroup from 'react-bootstrap/InputGroup';
import { calculateMaxHeightContent, formatNumber, unformatNumber } from '../../resources/functions';
import { OpennigBalanceFormI } from '../../resources/form-props';
import { OpenningBalanceValidate } from '../../resources/form-validator';
import { useChartAccountApi } from '../../resources/hooks/api/chartAccountApiHook';
import { useSettingsApi } from '../../resources/hooks/api/useSettingsApiHook';
import { getBranch } from '../../store/user/selectors'
import { URLS } from '../../resources/constants';
import '../../assets/css/datepicker.css'
import './openning-balance.css'
import { glClassificationApi } from '../../resources/hooks/api/glClassificationApi';
import { getAccountSettings } from '../../store/settings/selectors'
import SAlert from '../../components/SAlert';

interface Props {

}

const OpenningBalance: React.FC<Props> = () => {
    const branchData = useSelector(getBranch)
    const { fetchOpenningAcc, postOpenningAcc } = useChartAccountApi();
    const { fetchAccountSettingsData } = useSettingsApi();
    const { skipBeginningBalance } = glClassificationApi();
    const navigate = useNavigate();

    const _getAccountSettings = useSelector(getAccountSettings);

    const calendarRef = useRef<any>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalAssets, setTotalAssets] = useState<number>(0);
    const [totalLiabilities, setTotalLiabilities] = useState<number>(0);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<{
        submitForm: boolean,
        skipForm: boolean
    }>({
        submitForm: false,
        skipForm: false
    })
    const [selectedFilter, setSelectedFilter] = useState<any>({
        endDate: moment().endOf('month').format('YYYY-MM-DD')
    });
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0);

    const { register, control, setValue, getValues, reset, handleSubmit, formState: { errors } } = useForm<OpennigBalanceFormI>({
        defaultValues: {
            assets: [],
            liabilities: []
        },
        resolver: yupResolver(OpenningBalanceValidate),
    });
    const { fields: assetFields, append: addMoreAsset, remove: deleteAsset } = useFieldArray({
        control,
        name: 'assets',
    });
    const { fields: liabilitiesFields, append: addMoreLiabilities, remove: deleteLiabilities } = useFieldArray({
        control,
        name: 'liabilities',
    });

    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(78));
    })

    useEffect(() => {
        fetchList();
    }, [selectedFilter['endDate']])

    const resetForm = () => {
        setTotalAssets(0);
        setTotalLiabilities(0);

        reset({
            assets: [],
            liabilities: []
        });
    }

    const _handleChangeSearchParam = (e: any, type: string) => {
        if (moment(e).isValid()) {
            setSelectedFilter({
                'endDate': moment(e).endOf('month').format('YYYY-MM-DD')
            })
        }
    }

    const handleAssetsInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let inputValue = e.target.value.replace(/[^0-9.-]/g, '');
        const isNegative = inputValue.startsWith('-');

        // Allow input of negative numbers
        if (isNegative) {
            inputValue = inputValue.substring(1);
        }

        const formattedValue = formatNumber(inputValue);

        const result = isNegative ? `-${formattedValue}` : formattedValue;

        setValue(`assets.${index}.opening_balance`, result);
        calculateTotalAsset();
    };

    const handleLiabilitiesInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let inputValue = e.target.value.replace(/[^0-9.-]/g, '');
        const isNegative = inputValue.startsWith('-');

        // Allow input of negative numbers
        if (isNegative) {
            inputValue = inputValue.substring(1);
        }

        const formattedValue = formatNumber(inputValue);

        const result = isNegative ? `-${formattedValue}` : formattedValue;

        setValue(`liabilities.${index}.opening_balance`, result);
        calculateTotalBSLiabilities();
    };

    const openCalendar = () => {
        if (calendarRef.current) {
            calendarRef.current?.setOpen(true)
        }
    }

    const fetchList = () => {
        resetForm();

        const nextMonthDate = moment(selectedFilter['endDate']).format('YYYY-MM-DD');
        const params = {
            month: +moment(nextMonthDate).format('M'),
            year: +moment(nextMonthDate).format('Y'),
            branch_id: +branchData['id']
        }
        setIsLoading(true);
        fetchOpenningAcc(params, (message: string, resp: any) => {
            setIsLoading(false);

            let totalAsset = 0, totalLiabilities = 0;
            resp.data.assets.map((data: any) => {
                totalAsset += data['opening_balance'] || 0;
                addMoreAsset({
                    opening_balance: data['opening_balance'] ? formatNumber('' + data['opening_balance']) : '',
                    account: data['account'],
                    name: data['name'],
                    description: data['description'],
                    report_code: data['report_code'],
                    financial_statement: data['financial_statement'],
                    master_chart_of_account_id: data['master_chart_of_account_id'],
                    credit: data['credit'] || '',
                    debit: data['debit'] || '',
                })
            })
            resp.data.liabilities.map((data: any) => {
                totalLiabilities += data['opening_balance'] || 0;
                console.log('opening_balance: ', data['opening_balance'], ' totalLiabilities: ', totalLiabilities)
                addMoreLiabilities({
                    opening_balance: data['opening_balance'] ? formatNumber('' + data['opening_balance']) : '',
                    account: data['account'],
                    name: data['name'],
                    description: data['description'],
                    report_code: data['report_code'],
                    financial_statement: data['financial_statement'],
                    master_chart_of_account_id: data['master_chart_of_account_id'],
                    credit: data['credit'] || '',
                    debit: data['debit'] || '',
                })
            })
            console.log('totalLiabilities: ', totalLiabilities)
            setTotalAssets(parseFloat(totalAsset.toFixed(2)));
            setTotalLiabilities(parseFloat(totalLiabilities.toFixed(2)));
            // setIsEdit(resp.data.is_exists || false);
            // setIsEdit(false);

            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 100)
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }

    const _handleSubmit = (data: any) => {
        const queryString = {
            branch_id: +branchData['id'],
            month: +moment(selectedFilter['endDate']).format('M'),
            year: +moment(selectedFilter['endDate']).format('Y')
        }
        const formData = {
            "opening_balance": [
                ...data.assets.filter((e: any) => e['opening_balance'] != 0).map((e: any) => ({
                    ...e,
                    opening_balance: unformatNumber(e['opening_balance'])
                })),
                ...data.liabilities.filter((e: any) => e['opening_balance'] != 0).map((e: any) => ({
                    ...e,
                    opening_balance: unformatNumber(e['opening_balance'])
                })),
            ],
            "total_assets": totalAssets,
            "total_liabilities": totalLiabilities
        }

        if (totalAssets !== totalLiabilities) {
            toast.error('Total Assets and Total Liabilities must be equal');
            return;
        }

        setIsLoading(true)
        postOpenningAcc({ queryString, formData }, (message: string, resp: any) => {
            setIsLoading(false)
            toast.success(message);
            fetchList();
            getSettingsData();
        }, (message: string, resp: any) => {
            setIsLoading(false)
            toast.error(message);
        })
    }

    const submitForm = () => {
        setShowConfirm({ submitForm: true, skipForm: false });
        // handleSubmit(_handleSubmit)();
    };

    const calculateTotalAsset = () => {
        let total = 0;
        getValues('assets').map((item: any) => {
            total += item.account != 3999 ? (unformatNumber(item.opening_balance) || 0) : 0;
        })

        const gotIndex = getValues('assets').findIndex((item: any) => item.account == 3999);
        setTotalAssets(parseFloat(total.toFixed(2)))
        setValue(`assets.${gotIndex}.opening_balance`, '' + total);
    }
    const calculateTotalBSLiabilities = () => {
        let total = 0;
        getValues('liabilities').map((item: any) => {
            total += item.account != 5999 ? (unformatNumber(item.opening_balance) || 0) : 0;
        })
        const gotIndex = getValues('liabilities').findIndex((item: any) => item.account == 5999);
        setTotalLiabilities(parseFloat(total.toFixed(2)));
        setValue(`liabilities.${gotIndex}.opening_balance`, '' + total);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            event.preventDefault();
        }
    };

    const getSettingsData = () => {
        fetchAccountSettingsData({ branch_id: +branchData['id'] }, (msg: string, resp: any) => {

        }, (msg: string, resp: any) => {

        })
    }

    const completeCurrentStep = () => {
        setIsLoading(true);
        skipBeginningBalance({ 
            branch_id: +branchData['id'],
            month: +moment(selectedFilter['endDate']).format('M'),
            year: +moment(selectedFilter['endDate']).format('Y')
        }, (message: string, resp: any) => {
          setIsLoading(false);
          navigate(URLS.DASHBOARD)
        }, (message: string, resp: any) => {
          setIsLoading(false);
          toast.error(message);
        })
    }

    useEffect(() => {
        if ( _getAccountSettings.opening_balance_created_at || _getAccountSettings.opening_balance_skipped_at ) {
            setSelectedFilter({ endDate: moment(_getAccountSettings.opening_balance_created_at || _getAccountSettings.opening_balance_skipped_at).endOf('month').format('YYYY-MM-DD') });
            setIsEdit(false);
        } else {
            setIsEdit(true);
        }
    }, [_getAccountSettings.opening_balance_created_at, _getAccountSettings.opening_balance_skipped_at])

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Beginning Balance Entry</h1>
                        </div>
                    </div>
                </div>
                <div className="executive-dashboard">
                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                        <div className="loader"></div>
                    </div>
                    <div className="row">
                        <div className="align-items-end col-md-7 d-flex gap-2 justify-content-end">
                            {
                                !_getAccountSettings.is_beginning_balance_exists &&
                                (<Link to="javascript:void(0)" className="d-flex gap-2 align-items-center btn btn-cancel mb-3" onClick={() => {
                                    setShowConfirm({ submitForm: false, skipForm: true });
                                }}>
                                    <span>Click here to skip this step</span>
                                </Link>) || <></>
                            }
                            <div className="filter-box form-group date-picker-container mb-2">
                                <label htmlFor="">Ending date<strong className='text-danger'>*</strong></label>
                                <DatePicker
                                    ref={calendarRef}
                                    selected={moment(selectedFilter['endDate']).toDate()}
                                    onChange={(date: Date) => _handleChangeSearchParam(date, 'endDate')}
                                    className='form-control'
                                    dateFormat={'MM/dd/yyyy'}
                                    maxDate={new Date()}
                                    showMonthYearPicker
                                    calendarIconClassname='tex-end'
                                    disabled={!!_getAccountSettings.is_beginning_balance_exists}
                                />

                                <i className="fa fa-calendar fa-2xl cursor-pointer calendar-icon" aria-hidden="true" onClick={openCalendar}></i>
                            </div>
                        </div>
                    </div>


                    <div style={{ padding: 0, margin: 0, textAlign: 'center' }}>
                        <div className='col-md-7 d-flex justify-content-center'>

                        <Form className='w-100' onKeyDown={handleKeyDown} /*onSubmit={handleSubmit(saveMonthlyIncome)}*/>
                            <div>
                                <table className="openning-balance table boder-padding-none">
                                    <tr>
                                        <td colSpan={12}>
                                            <table className="section-table">
                                                <tr className="header">
                                                    <td width={'10%'}>Account</td>
                                                    <td width={'60%'}>Name</td>
                                                    <td width={'40%'}>Amount (in $)</td>
                                                </tr>
                                                {assetFields.map((item: any, index: number) => (
                                                    <tr key={item.id} className={`${['GH', 'SH', "SSH"].includes(item.report_code) ? 'grey-background' : ''} ${['GT', 'ST', 'SST'].includes(item.report_code) ? 'total-row' : ''}`}>
                                                        <td className='text-start'>
                                                            <label htmlFor="">
                                                                {
                                                                    ['VP', 'IP', "VP/IP"].includes(item.report_code) ?
                                                                        getValues(`assets.${index}.account`) : getValues(`assets.${index}.account`) == 3999 ? (<span style={{ fontSize: 'larger' }}>{getValues(`assets.${index}.account`)}</span>) : ['GT', 'ST', 'SST'].includes(item.report_code) ? (
                                                                            <span></span>
                                                                        ) : <span>{getValues(`assets.${index}.account`)}</span>
                                                                }
                                                            </label>
                                                        </td>
                                                        <td className='text-start'>
                                                            {
                                                                ['VP', 'IP', "VP/IP"].includes(item.report_code) ?
                                                                    <input
                                                                        type='text'
                                                                        {...register(`assets.${index}.name`)}
                                                                        placeholder="Name"
                                                                        readOnly={!isEdit}
                                                                        className={`form-control ${errors && errors.assets && errors.assets[index]?.name ? 'is-invalid' : ''} ${isEdit ? '' : 'input-disabled-look'}`}
                                                                    /> : <label htmlFor="">
                                                                        {
                                                                            getValues(`assets.${index}.account`) == 3999 ?
                                                                                <span style={{ fontSize: 'larger' }}>
                                                                                    {getValues(`assets.${index}.name`)}
                                                                                </span> :
                                                                                <span>
                                                                                    {getValues(`assets.${index}.name`)}
                                                                                </span>
                                                                        }
                                                                    </label>
                                                            }
                                                        </td>
                                                        {
                                                            ['VP', 'IP', "VP/IP"].includes(item.report_code) ? (
                                                                <td>
                                                                    <InputGroup hasValidation className="input-group-custom">
                                                                        <InputGroup.Text>$</InputGroup.Text>
                                                                        <input
                                                                            type='text'
                                                                            {...register(`assets.${index}.opening_balance`)}
                                                                            placeholder="Amount"
                                                                            onChange={(e) => handleAssetsInputChange(e, index)}
                                                                            readOnly={!isEdit}
                                                                            className={`form-control ${isEdit ? '' : 'input-disabled-look'}`}
                                                                        />
                                                                    </InputGroup>
                                                                </td>
                                                            ) : <td className='text-start'>
                                                                <label htmlFor="">
                                                                    {
                                                                        getValues(`assets.${index}.account`) == 3999 ? <strong style={{ fontSize: 'larger' }}>{'$' + formatNumber(parseFloat('' + totalAssets).toFixed(2))}</strong> : ''
                                                                    }
                                                                </label>
                                                            </td>
                                                        }
                                                    </tr>
                                                ))}
                                                {liabilitiesFields.map((item: any, index: number) => (
                                                    <tr key={item.id} className={`${['GH', 'SH', "SSH"].includes(item.report_code) ? 'grey-background' : ''} ${['GT', 'ST', 'SST'].includes(item.report_code) ? 'total-row' : ''}`}>
                                                        <td className='text-start'>
                                                            <label htmlFor="">
                                                                {
                                                                    ['VP', 'IP', "VP/IP"].includes(item.report_code) ?
                                                                        getValues(`liabilities.${index}.account`) : getValues(`liabilities.${index}.account`) == 5999 ? (<span style={{ fontSize: 'larger' }}>{getValues(`liabilities.${index}.account`)}</span>) : ['GT', 'ST', 'SST'].includes(item.report_code) ? (
                                                                            <span></span>
                                                                        ) : <span>{getValues(`liabilities.${index}.account`)}</span>
                                                                }
                                                            </label>
                                                        </td>
                                                        <td className='text-start'>
                                                            {
                                                                ['VP', 'IP', "VP/IP"].includes(item.report_code) ?
                                                                    <input
                                                                        type='text'
                                                                        {...register(`liabilities.${index}.name`)}
                                                                        placeholder="Name"
                                                                        readOnly={!isEdit}
                                                                        className={`form-control ${errors && errors.liabilities && errors.liabilities[index]?.name ? 'is-invalid' : ''} ${isEdit ? '' : 'input-disabled-look'}`}
                                                                    /> : <label htmlFor="">
                                                                        {
                                                                            getValues(`liabilities.${index}.account`) == 5999 ?
                                                                                <span style={{ fontSize: 'larger' }}>
                                                                                    {getValues(`liabilities.${index}.name`)}
                                                                                </span> :
                                                                                <span>
                                                                                    {getValues(`liabilities.${index}.name`)}
                                                                                </span>
                                                                        }
                                                                    </label>
                                                            }
                                                        </td>
                                                        {
                                                            ['VP', 'IP', "VP/IP"].includes(item.report_code) ? (
                                                                <td>
                                                                    <InputGroup hasValidation className="input-group-custom">
                                                                        <InputGroup.Text>$</InputGroup.Text>
                                                                        <input
                                                                            type='text'
                                                                            {...register(`liabilities.${index}.opening_balance`)}
                                                                            placeholder="Amount"
                                                                            onChange={(e) => handleLiabilitiesInputChange(e, index)}
                                                                            readOnly={!isEdit}
                                                                            className={`form-control ${isEdit ? '' : 'input-disabled-look'}`}
                                                                        />
                                                                    </InputGroup>
                                                                </td>
                                                            ) : <td className='text-start'>
                                                                <label htmlFor="">
                                                                    {
                                                                        getValues(`liabilities.${index}.account`) == 5999 ? <strong style={{ fontSize: 'larger' }}>{'$' + formatNumber(parseFloat('' + totalLiabilities).toFixed(2))}</strong> : ''
                                                                    }
                                                                </label>
                                                            </td>
                                                        }
                                                    </tr>
                                                ))}
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            {
                                !_getAccountSettings.is_beginning_balance_exists ? (
                                    <button type="button" onClick={submitForm} className="btn btn-primary mt-4">Save Beginning Balance</button>
                                ) : <></>
                            }
                        </Form>
                        </div>
                    </div>

                </div>
            </div>
            <SAlert
                show={showConfirm.skipForm || showConfirm.submitForm}
                icon={'warning'}
                showCancel={true}
                confirmBtnText={'Yes'}
                cancelBtnText={"No"}
                confirmBtnBsStyle={'danger'}
                cancelBtnBsStyle={"secondary"}
                title={`Are you sure to ${ showConfirm.skipForm ? 'skip' : 'submit' } the begining balance for ${moment(selectedFilter['endDate']).format('MMM, YYYY')}?`}
                msg={"You won't be able to revert this!"}
                onConfirm={() => {
                    if ( showConfirm.skipForm ) {
                        completeCurrentStep();
                        setShowConfirm({ submitForm: false, skipForm: false });
                    } else {
                        handleSubmit(_handleSubmit)()
                        setShowConfirm({ submitForm: false, skipForm: false });
                    }
                }}
                onCancel={() => setShowConfirm({ submitForm: false, skipForm: false })}
            />
        </div>

    )
}

export default OpenningBalance;